import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GrowthService {

  constructor(private http : HttpClient) { }
  GetAllProjects() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/project-names`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  getGrowthNames() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/get-pms-growth-name`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
      });
      return promise;
  }


  SubmitAPI(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/add-growth-report`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  
  //DatatableAPIs
  GetAllReportParameters() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-growthname-for-report`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }


  GetCSATReportData(iProjectId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-growth-csat-report?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  getGaugeChartData(iProjectId?:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/feedback-details?iProjectId=${iProjectId}`;
      this.http
      .get(apiURL)
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
  
}
  GetNPSReportData(iProjectId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-growth-nps-report?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetWSRReportData(iProjectId:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-growth-wsr-report?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetOppotunityData(iProjectId?:any){
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/get-opportunity?iProjectId=${iProjectId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  GetAllMembersForTag() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-tag-member-info`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
