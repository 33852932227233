import { Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import { random } from "lodash";
import * as XLSX from "xlsx";
import { DomSanitizer } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';
import { Options } from '@angular-slider/ngx-slider';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChatToolService } from "../partner/project-management/chat-tool/chat-tool.service";
import Gantt from 'frappe-gantt';
import { ProjectManagementService } from "../partner/project-management/project-management.service";

@Component({
  selector: "app-design-system",
  templateUrl: "./design-system.component.html",
  // animations: [
  //   trigger('fadeIn', [
  //     state('void', style({ opacity: 0, transform: 'scale(0.8)' })),
  //     transition('void <=> *', animate('0.5s')),
  //   ]),
  // ],
})




export class DesignSystemComponent implements OnInit {
  @ViewChild('gantt') ganttElement!: ElementRef;

  gantt: any;
  tasks = [
    {
      id: 'p1',
      name: 'Project 1',
      start: '2022-12-1',
      end: '2026-01-22',
      progress: 20,
      dependencies: '',
    },
    {
      id: 'm1',
      name: 'Milestone 1',
      start: '2023-10-1',
      end: '2023-10-30',
      progress: 20,
      dependencies: 'p1',
    },
    {
      id: 't1',
      name: 'Task 1',
      start: '2023-10-1',
      end: '2023-10-14',
      progress: 20,
      dependencies: 'm1',
    },
    {
      id: 't2',
      name: 'Task 2',
      start: '2023-10-14',
      end: '2023-10-29',
      progress: 20,
      dependencies: 'm1',
    },
    {
      id: 'm2',
      name: 'Milestone 2',
      start: '2023-11-1',
      end: '2023-11-30',
      progress: 20,
      dependencies: 'p1',
    },
    {
      id: 't3',
      name: 'Task 3',
      start: '2023-11-01',
      end: '2023-11-12',
      progress: 20,
      dependencies: 'm2',
    },
    {
      id: 't4',
      name: 'Task 4',
      start: '2023-11-13',
      end: '2023-11-29',
      progress: 20,
      dependencies: 'm2',
    },
  ];

  value: number = 10;
  options: Options = {
    floor: 0,
    showSelectionBar: false,
    ceil: 100,
  };

  // value: number = 5;
  // options: Options = {
  //   showTicksValues: true,
  //   stepsArray: [
  //     { value: 1, legend: "0-5k" },
  //     { value: 2, legend: "10-20k" },
  //     { value: 3, legend: "40-50k" },
  //     { value: 4, legend: "50-100k" },
  //     { value: 5, legend: "+100k" }
  //   ]
  // };

  sanitizeHTML(html: string): string {
    return this.sanitizer.bypassSecurityTrustHtml(html) as string;
  }

  @ViewChild('contentToExport', { static: true }) contentToExport!: ElementRef;

  exportToExcel() {
    if (this.contentToExport) {
      // Get the inner HTML content of the element and sanitize it to remove HTML tags
      const content = DOMPurify.sanitize(this.contentToExport.nativeElement.innerHTML, { ALLOWED_TAGS: [] });

      // Create a new Excel workbook
      const workbook = XLSX.utils.book_new();

      // Create a worksheet with the sanitized content
      const worksheet = XLSX.utils.aoa_to_sheet([[''], [content]]);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate a binary representation of the Excel workbook
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob from the Excel buffer
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'exported-content.xlsx';
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } else {
      console.error('contentToExport is undefined');
    }
  }
  
  public canvasWidth : any;
  public needleValue : any;
  public centralLabel : any;
  public label : any;
  // public options : any;

  skills: any = [
    { value: "1", viewValue: "Frontend" },
    { value: "2", viewValue: "Backend" },
    { value: "3", viewValue: "Fullstack" },
  ];
  selectedSkills = "0";
  hidePassword = true;
  unauthorized:any = 1;

  constructor(private sanitizer: DomSanitizer,private chatService: ChatToolService, private pmService: ProjectManagementService) {}

  ngOnInit(): void {
    this.canvasWidth = 500;
    this.needleValue = 60;
    this.centralLabel = '';
    // this.options = {
    //   hasNeedle: true,
    //   needleColor: 'grayblack',
    //   needleUpdateSpeed: 800,
    //   arcColors: [
    //     'red',
    //     'yellow',
    //     'green',
    //     'blue',
    //     'red',
    //     'yellow',
    //     'green',
    //     'green',
    //   ],
    //   arcDelimiters: [10,20,30,40,50,60,70,80,90],
    //   arcLabel:[10,20,30],
    //   rangeLabel: ['Bad','Good'],
    //   needleStartValue: 0,
    //   arcLabels: ['10', '20', '30', '40', '50', '60', '70', '80', '90'],
    //   arcpadding: 5,
    // };

    this.gantt = new Gantt('#gantt', this.tasks,{
      on_click: function (task) {
        console.log(task);
      },
      on_date_change: function(task:any, start:any, end:any) {
        console.log(task, start, end);
      },
      on_progress_change: function(task, progress) {
        console.log(task, progress);
      },
      on_view_change: function(mode) {
        console.log(mode);
      }
    });
    this.gantt.change_view_mode('Month') 
  }
  
   changeView(val:any){
    this.gantt.change_view_mode(val) // Quarter Day, Half Day, Day, Week, Month 
   }

   change(){
    this.needleValue=Math.floor(Math.random() * 100);
   }

  Onclick(){
    console.log("Hiiiiiiiiiiii");
    
  }
  description: string = `It's a :rocket:`;
  description1: string = `It's a :grinning:`;
  description2: string = `It's a :slightly_smiling_face:`;
  description3: string = `It's a :hugging_face:`;
  description4: string = `It's a :blush:`;
  description5: string = `It's a :unamused:`;
 
  chatMessage = {
    text: 'This is a **user and __client__ [example](https://example.com) text** :grinning:'
  };

  formatMessage(message: string): string {
    message = message.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    message = message.replace(/__(.*?)__/g, '<i>$1</i>');
    message = message.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
    message = message.replace(/(:\)|:grinning:;)/g, '<span>:grinning:</span>');
    return message;
  }
  generateFirstValue() {
    let someDate = new Date();
    var numberOfDaysToAdd = this.value;
    var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  }

  
  // showTextarea: boolean = false;
  // textAreaContent: string = '';

  // toggleView() {
  //   this.showTextarea = !this.showTextarea;
  // }

  // Assuming you have something like this in your component class
showTextarea: boolean = false;
textAreaContent: string = '';

toggleView() {
  this.showTextarea = !this.showTextarea;
}

updateTextAreaContent(event: Event) {
  // Assuming you want to update textAreaContent based on some event
  this.textAreaContent = (event.target as HTMLInputElement).value;
}


APIcalling(){
  let reqJson = {}
  this.chatService.CreateMembers(reqJson).then((result: any) => {
    if (result.code == 200) {
      console.log("success")
    } else {
      console.log("failed")
    }
  });
}

TransferAssigneeForHelpdesk(){
  let reqJson = {}
  this.pmService.TransferAssigneeForHelpdesk(reqJson).then((result: any) => {
    if (result.code == 200) {
      console.log("success")
    } else {
      console.log("failed")
    }
  });
}
}
