<div>
  <div class="pr-chart-container d-flex justify-content-between align-items-start">
    <div>
      <app-ng7-dynamic-breadcrumb></app-ng7-dynamic-breadcrumb>
      <span class="title">Growth</span>
    </div>
    <div class="card-filter-btn">
      <mat-form-field hideRequiredMarker floatLabel="never" floatPlaceholder="never" class="filter-field"
        name="project-dropdown-col-1-select" id="project-dropdown-col-1-select" [ngClass]="{'inner': projectFlag}">
        <mat-select name="tiFilterId" id="tiFilterId" placeholder="Select Project" panelClass="project-filter-position"
          [disabled]="projectFlag" [(value)]="projectId" (selectionChange)="GetProjectByFilter($event)" required>
          
          <mat-option *ngFor="let project of getAllProjects; let i = index" [value]="project.iProjectId"
            class="rm-mat-option" [title]="project.getAllProjects">
            {{ project.vProjectName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="main-div mt-4">
    <div class="chart-div">
      <div class="p-card p-3 p-sm-5 project-other">
        <h2 class="p-title-growth mb-2">CSAT(%)</h2>
        <rg-gauge-chart class="guageChart" [canvasWidth]="canvasWidthCsat" [needleValue]="needleValueCsat"
          [bottomLabel]="needleValueCsat" [options]="optionsCsat">
        </rg-gauge-chart>
        <div class="d-flex justify-content-center align-items-center">
          <p class="que-text">What would you rate for overall experience?</p>
        </div>
      </div>



      <div class="p-card p-3 p-sm-5 project-other">
        <h2 class="p-title-growth mb-2">NPS(%)</h2>
        <rg-gauge-chart class="guageChart" [canvasWidth]="canvasWidthNps" [needleValue]="needleValueNps"
          [bottomLabel]="needleValueNps" [options]="optionsNps">
        </rg-gauge-chart>
        <div class="d-flex justify-content-center align-items-center">
          <p class="que-text">How Likely are you to recommend us to a friend?</p>
        </div>
      </div>
    </div>
    <div class="project-growth">
      <label class="no-data-found d-flex justify-content-center align-items-center h-100"
        *ngIf="dataSource?.length==0">
        No Opportunities Found
      </label>
      <div class="table-oppotunity"*ngIf="dataSource?.length>0">
      <mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter">

        <ng-container matColumnDef="iCreatedAt">
          <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.iCreatedAt *1000 | date : 'dd MMM, yyyy'}} </mat-cell>
        </ng-container>
 
        <ng-container matColumnDef="vValueAdditionTitle">
          <mat-header-cell *matHeaderCellDef> Opportunity Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vValueAdditionTitle}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="txValueAdditionDescription">
          <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.txValueAdditionDescription}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vResponsibleBy">
          <mat-header-cell *matHeaderCellDef> Responsible Person</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vResponsibleBy}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tiOpportunityStatus" style="display: none;">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="!element.tiOpportunityStatus">--</span>
            <span *ngIf="element.tiOpportunityStatus == 1" class="color-open">Open</span>
            <span *ngIf="element.tiOpportunityStatus == 2" class="color-progress">InProgress</span>
            <span *ngIf="element.tiOpportunityStatus == 3" class="color-won">Won</span>  
            <span *ngIf="element.tiOpportunityStatus == 4" class="color-lost">Lost</span>
            <span *ngIf="element.tiOpportunityStatus == 5" class="color-hold">Hold</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <div *ngIf="dataSource?.length > 0"
      [ngClass]="dataSource?.length == 0 ? 'disabled-btn-csv':''" class="m-3">
      <p class="text-primary">Click the 'Download CSV' button below to retrieve the opportunity list in CSV format for easy access.</p>
      <button type="button" class="log-card-view-white m-0 log-csv-btn"
          (click)="matTableExporter.exportTable('csv', { fileName: 'Opportunity Report' })"
          [disabled]="dataSource?.length == 0 "
          [ngClass]="dataSource?.length == 0 ? 'disabled-btn-DownloadCSV':''">Download
          CSV&nbsp;&nbsp;<img class="info-tooltip mx-1 update-icon"
              [src]="imagePath + 'downloadCSV.svg'" alt="CSV" /> </button>
    </div>
    </div>
  </div>

  <div class="main-div-2 mt-4" *ngIf="projectId==0 ? '' : 'isdivShow'">
    <div class="center-div growth-div">
      <label class="no-data-found d-flex justify-content-center align-items-center h-100"
        *ngIf="CsatArray?.length==0">
        Delivery Report Parameters Not Found
      </label>
      <div class="display-div mt-2" *ngIf="CsatArray?.length>0">
        <div class="delivery-div">Delivery(CSAT)</div>
        <div class="set-checkbox">
          <mat-checkbox class="input-checkbox"
          [checked]="selectAllCsat"
          (change)="selectCsatAll($event.checked)">
          <span class="input-label">Select All</span></mat-checkbox>
        </div>
      </div>

      <div class="set-emoji" *ngIf="CsatArray?.length>0">
        <div class="smily"><img src="https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/slightly-smiling-face.png"
            alt="" width="100" height="100"></div>
      </div>
      <div class="content-div" *ngIf="CsatArray?.length>0">
        <div class="btn-textks mt-2" *ngFor="let obj of CsatArray;">
          <button class="btn-emoji mb-4" [ngClass]="{'selected':obj.tiPmsGrowth==true}"
            (click)="SelectParameter(obj,1)">{{obj.vPmsGrowthName}}</button>
        </div>
      </div>

      <div class="col-12 sub-div" *ngIf="CsatArray?.length>0">
        <form [formGroup]="DeliveryForm" name="DeliveryForm" id="DeliveryForm" class="form mat-form-field-data">
          <div name="add-comment-col-11" id="add-comment-col-11" class="col-12 field-box">
            <mat-form-field name="mat-form-field-add-comment" id="mat-form-field-add-comment"
              class="custom-input textarea" floatLabel="never" floatPlaceholder="never">
              <textarea matInput rows="3" name="txCommentCSAT" id="txCommentCSAT" formControlName="txComment"
                placeholder="Add Comments" #txCommentCSAT></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter rm-view"><span
                  name="bio-character-count-2" id="bio-character-count-2" class="me-1"
                  [ngClass]="{ exceeded: txCommentCSAT.value.length > 600 }">{{
                  txCommentCSAT.value.length }}</span>
                / 600</span>
            </mat-form-field>
          </div>
        </form>
        <div class="submit-btn">
          <button type="button" class="btn btn-primary Csat" (click)="SubmitReport(1,submitGrowthReportActionPermission)" [ngClass]="submitGrowthReportActionPermission ? '':'unauthorized-cursor'" placement="right auto" [title]="submitGrowthReportActionPermission ?  '':'Not Authorized'">Submit</button>
        </div>
      </div>

    </div>
    <div class="center-div">
    <label class="no-data-found d-flex justify-content-center align-items-center h-100"
    *ngIf="csatReportArray?.length==0">
    No Delivery Report Found
  </label>
    <div class="growth-table-container" *ngIf="csatReportArray?.length>0">
    <table>
      <thead>
        <tr>
          <th class="table-title-blue">Delivery Report</th>
          <ng-container *ngFor="let date of uniqueDatesCSAT">
            <ng-container *ngFor="let subcolumn of uniqueSubcolumnsForDate(date,1)">
              <!-- <th>{{ date }} ({{ subcolumn }})</th> -->
              <th>{{ date }}</th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let parameter of parametersInFirstColumnCSAT">
          <tr>
            <td>{{ parameter }}</td>
            <ng-container *ngFor="let date of uniqueDatesCSAT">
              <ng-container *ngFor="let subcolumn of uniqueSubcolumnsForDate(date,1)">
                <td *ngIf="tableDataCSAT[parameter] && tableDataCSAT[parameter][date] && tableDataCSAT[parameter][date][subcolumn] !== undefined">
                  <!-- {{ tableDataCSAT[parameter][date][subcolumn] === true ? 'true' : tableDataCSAT[parameter][date][subcolumn] === false ?  'false' : tableDataCSAT[parameter][date][subcolumn] }} -->
                  <ng-container *ngIf="tableDataCSAT[parameter][date][subcolumn] === true || tableDataCSAT[parameter][date][subcolumn] === false; else commentConditionCSAT">
                    <img *ngIf="tableDataCSAT[parameter][date][subcolumn] === true" [src]="imagePath + 'check_true.svg'" alt="True">
                    <img *ngIf="tableDataCSAT[parameter][date][subcolumn] === false" [src]="imagePath + 'check_false.svg'" alt="False">
                  </ng-container>
                  <ng-template #commentConditionCSAT>
                    <span class="comment-text" (click)="ViewComment(viewCommentModal,tableDataCSAT[parameter][date][subcolumn])">{{tableDataCSAT[parameter][date][subcolumn] | slice:0:175}}&nbsp;<span *ngIf="tableDataCSAT[parameter][date][subcolumn]?.length>175"
                      class="read-more">...</span></span>
                  </ng-template>
                </td>
                <td *ngIf="!tableDataCSAT[parameter] || !tableDataCSAT[parameter][date] || tableDataCSAT[parameter][date][subcolumn] === undefined">
                  --
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
    </div> 

  </div>

  <div class="main-div-2 mt-4" *ngIf="projectId==0 ? '' : 'isdivShow' ">
    <div class="center-div growth-div">
      <label class="no-data-found d-flex justify-content-center align-items-center h-100"
        *ngIf="NpsArray?.length==0">
        Experience Report Parameters Not Found
      </label>
      <div class="display-div mt-2" *ngIf="NpsArray?.length>0">
        <div class="delivery-div">Experience(NPS)</div>
        <div class="set-checkbox ">
          <mat-checkbox class="input-checkbox"
                  [checked]="selectAllNps"
                  (change)="selectNpsAll($event.checked)">
          <span class="input-label">Select All</span></mat-checkbox>
        </div>
      </div>

      <div class="set-emoji" *ngIf="NpsArray?.length>0">
        <div class="smily"><img src="https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/slightly-smiling-face.png"
            alt="" width="100" height="100"></div>
      </div>
      <div class="content-div" *ngIf="NpsArray?.length>0">
        <div class="btn-textks mt-2" *ngFor="let obj of NpsArray">
          <button class="btn-emoji mb-4" [ngClass]="{'selected-experience':obj.tiPmsGrowth==true}"
            (click)="SelectParameter(obj,2)">{{obj.vPmsGrowthName}}</button>
        </div>
      </div>

      <div class="col-12 sub-div" *ngIf="NpsArray?.length>0">
        <form [formGroup]="ExperienceForm" name="ExperienceForm" id="ExperienceForm" class="form mat-form-field-data">
          <div name="add-comment-col-11" id="add-comment-col-11" class="col-12 field-box">
            <mat-form-field name="mat-form-field-add-comment" id="mat-form-field-add-comment"
              class="custom-input textarea" floatLabel="never" floatPlaceholder="never">
              <textarea matInput rows="3" name="txCommentNPS" id="txCommentNPS" formControlName="txComment"
                placeholder="Add Comments" #txCommentNPS></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter rm-view"><span
                  name="bio-character-count-2" id="bio-character-count-2" class="me-1"
                  [ngClass]="{ exceeded: txCommentNPS.value.length > 600 }">{{
                  txCommentNPS.value.length }}</span>
                / 600</span>
            </mat-form-field>
          </div>
        </form>
        <div class="submit-btn">
          <button type="button" class="btn btn-primary experience" (click)="SubmitReport(2,submitGrowthReportActionPermission)" [ngClass]="submitGrowthReportActionPermission ? '':'unauthorized-cursor'" placement="right auto" [title]="submitGrowthReportActionPermission ?  '':'Not Authorized'">Submit</button>
        </div>
      </div>

      
    </div>
    <div class="center-div">
      <label class="no-data-found d-flex justify-content-center align-items-center h-100"
      *ngIf="npsReportArray?.length==0">
      No Experience Report Found
    </label>
      <div class="growth-table-container" *ngIf="npsReportArray?.length>0">
      <table>
        <thead>
          <tr>
            <th class="table-title-pink">Experience Report</th>
            <ng-container *ngFor="let date of uniqueDatesNPS">
              <ng-container *ngFor="let subcolumn of uniqueSubcolumnsForDate(date,2)">
                <!-- <th>{{ date }} ({{ subcolumn }})</th> -->
                <th>{{ date }}</th>
              </ng-container>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let parameter of parametersInFirstColumnNPS">
            <tr>
              <td>{{ parameter }}</td>
              <ng-container *ngFor="let date of uniqueDatesNPS">
                <ng-container *ngFor="let subcolumn of uniqueSubcolumnsForDate(date,2)">
                  <td *ngIf="tableDataNPS[parameter] && tableDataNPS[parameter][date] && tableDataNPS[parameter][date][subcolumn] !== undefined">
                    <!-- {{ tableDataNPS[parameter][date][subcolumn] === true ? 'true' : tableDataNPS[parameter][date][subcolumn] === false ?  'false' : tableDataNPS[parameter][date][subcolumn] }} -->
                    <ng-container *ngIf="tableDataNPS[parameter][date][subcolumn] === true || tableDataNPS[parameter][date][subcolumn] === false; else commentConditionNPS">
                      <!-- <img *ngIf="tableDataNPS[parameter][date][subcolumn] === true"  [src]="imagePath + 'check_true.svg'" alt="True"> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"  *ngIf="tableDataNPS[parameter][date][subcolumn] === true" >
                        <path id="check_box_FILL1_wght400_GRAD0_opsz24" d="M128.444-825.333l7.833-7.833-1.556-1.556-6.278,6.278-3.167-3.167-1.556,1.556ZM122.222-820a2.14,2.14,0,0,1-1.569-.653,2.14,2.14,0,0,1-.653-1.569v-15.556a2.14,2.14,0,0,1,.653-1.569,2.14,2.14,0,0,1,1.569-.653h15.556a2.14,2.14,0,0,1,1.569.653,2.14,2.14,0,0,1,.653,1.569v15.556a2.14,2.14,0,0,1-.653,1.569,2.14,2.14,0,0,1-1.569.653Z" transform="translate(-120 840)" fill="#EC407A"/>
                      </svg>
                      <img *ngIf="tableDataNPS[parameter][date][subcolumn] === false" [src]="imagePath + 'check_false.svg'" alt="False">
                    </ng-container>
                    <ng-template #commentConditionNPS>
                      <span class="comment-text" (click)="ViewComment(viewCommentModal,tableDataNPS[parameter][date][subcolumn])">{{tableDataNPS[parameter][date][subcolumn] | slice:0:175}}&nbsp;<span *ngIf="tableDataNPS[parameter][date][subcolumn]?.length>175"
                        class="read-more">...</span></span>
                    </ng-template>
                  </td>
                  <td *ngIf="!tableDataNPS[parameter] || !tableDataNPS[parameter][date] || tableDataNPS[parameter][date][subcolumn] === undefined">
                    --
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
      </div> 

  </div>

  <div class="main-div-2 mt-4" *ngIf="projectId==0 ? '' : 'isdivShow' ">
    <div class="center-div growth-div">
      <label class="no-data-found d-flex justify-content-center align-items-center h-100"
        *ngIf="WsrArray?.length==0">
        Weekly Report Parameters Not Found
      </label>
      <div class="display-div mt-2" *ngIf="WsrArray?.length>0">
        <div class="WSR-div">WSR</div>
        <div class="WSR-checkbox">
          <mat-checkbox class="input-checkbox"
                  [checked]="selectAllWsr"
                  (change)="selectWsrAll($event.checked)">
          <span class="input-label">Select All</span></mat-checkbox>
        </div>
      </div>

      <div class="set-emoji" *ngIf="WsrArray?.length>0">
        <div class="smily"><img src="https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/slightly-smiling-face.png"
            alt="" width="100" height="100"></div>
      </div>
      <div class="content-div" *ngIf="WsrArray?.length>0">
        <div class="btn-textks mt-2" *ngFor="let obj of WsrArray">
          <button class="btn-emoji mb-4" [ngClass]="{'selected-WSR':obj.tiPmsGrowth==true}"
            (click)="SelectParameter(obj,3)">{{obj.vPmsGrowthName}}</button>
        </div>
      </div>

      <div class="col-12 sub-div" *ngIf="WsrArray?.length>0">
        <form [formGroup]="WSRForm" name="WSRForm" id="WSRForm" class="form mat-form-field-data">
          <div name="add-comment-col-11" id="add-comment-col-11" class="col-12 field-box">
            <mat-form-field name="mat-form-field-add-comment" id="mat-form-field-add-comment"
              class="custom-input textarea" floatLabel="never" floatPlaceholder="never">
              <textarea matInput rows="3" name="txCommentWSR" id="txCommentWSR" formControlName="txComment"
                placeholder="Add Comments" #txCommentWSR></textarea>
              <span name="bio-character-count-1" id="bio-character-count-1" class="bio-character-counter rm-view"><span
                  name="bio-character-count-2" id="bio-character-count-2" class="me-1"
                  [ngClass]="{ exceeded: txCommentWSR.value.length > 600 }">{{
                  txCommentWSR.value.length }}</span>
                / 600</span>
            </mat-form-field>
          </div>
        </form>
        <div class="submit-btn">
          <button type="button" class="btn btn-primary wsr" (click)="SubmitReport(3,submitGrowthReportActionPermission)" [ngClass]="submitGrowthReportActionPermission ? '':'unauthorized-cursor'" placement="right auto" [title]="submitGrowthReportActionPermission ?  '':'Not Authorized'">Submit</button>
        </div>
      </div>
    </div>
    <div class="center-div">
      <label class="no-data-found d-flex justify-content-center align-items-center h-100"
      *ngIf="wsrReportArray?.length==0">
      No Weekly Report Found
    </label>
      <div class="growth-table-container" *ngIf="wsrReportArray?.length>0">
      <table>
        <thead>
          <tr>
            <th class="table-title-green">Weekly Report</th>
            <ng-container *ngFor="let date of uniqueDatesWSR">
              <ng-container *ngFor="let subcolumn of uniqueSubcolumnsForDate(date,3)">
                <!-- <th>{{ date }} ({{ subcolumn }})</th> -->
                <th>{{ date }}</th>
              </ng-container>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let parameter of parametersInFirstColumnWSR">
            <tr>
              <td>{{ parameter }}</td>
              <ng-container *ngFor="let date of uniqueDatesWSR">
                <ng-container *ngFor="let subcolumn of uniqueSubcolumnsForDate(date,3)">
                  <td *ngIf="tableDataWSR[parameter] && tableDataWSR[parameter][date] && tableDataWSR[parameter][date][subcolumn] !== undefined">
                    <!-- {{ tableDataWSR[parameter][date][subcolumn] === true ? 'true' : tableDataWSR[parameter][date][subcolumn] === false ?  'false' : tableDataWSR[parameter][date][subcolumn] }} -->
                    <ng-container *ngIf="tableDataWSR[parameter][date][subcolumn] === true || tableDataWSR[parameter][date][subcolumn] === false; else commentConditionWSR">
                      <!-- <img *ngIf="tableDataWSR[parameter][date][subcolumn] === true" [src]="imagePath + 'check_true.svg'" alt="True"> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"  *ngIf="tableDataWSR[parameter][date][subcolumn] === true">
                        <path id="check_box_FILL1_wght400_GRAD0_opsz24" d="M128.444-825.333l7.833-7.833-1.556-1.556-6.278,6.278-3.167-3.167-1.556,1.556ZM122.222-820a2.14,2.14,0,0,1-1.569-.653,2.14,2.14,0,0,1-.653-1.569v-15.556a2.14,2.14,0,0,1,.653-1.569,2.14,2.14,0,0,1,1.569-.653h15.556a2.14,2.14,0,0,1,1.569.653,2.14,2.14,0,0,1,.653,1.569v15.556a2.14,2.14,0,0,1-.653,1.569,2.14,2.14,0,0,1-1.569.653Z" transform="translate(-120 840)" fill="#58B47C"/>
                      </svg>
                      <img *ngIf="tableDataWSR[parameter][date][subcolumn] === false" [src]="imagePath + 'check_false.svg'" alt="False">
                    </ng-container>
                    <ng-template #commentConditionWSR>
                      <span class="comment-text" (click)="ViewComment(viewCommentModal,tableDataWSR[parameter][date][subcolumn])">{{tableDataWSR[parameter][date][subcolumn] | slice:0:175}}&nbsp;<span *ngIf="tableDataWSR[parameter][date][subcolumn]?.length>175"
                        class="read-more">...</span></span>
                    </ng-template>
                  </td>
                  <td *ngIf="!tableDataWSR[parameter] || !tableDataWSR[parameter][date] || tableDataWSR[parameter][date][subcolumn] === undefined">
                    --
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
      </div> 
  </div>  
</div>


<!-- View Comment -->
<ng-template #viewCommentModal let-c="close" let-d="dismiss">
  <div name="view-project-description-modal-header" id="view-project-description-modal-header" class="modal-header">
    <h1 name="view-project-description-modal-title" id="view-project-description-modal-title" class="modal-title">
      Comment
    </h1>
    <button (click)="CloseModal()" class="btn-close-custom"><mat-icon>close</mat-icon></button>
  </div>
  <perfect-scrollbar>
    <div name="view-project-description-modal-body" id="view-project-description-modal-body"
      class="modal-body modal-scroll">
      <p>{{this.descriptionText}}</p>
    </div>
  </perfect-scrollbar>

</ng-template>
