import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject,Observable } from "rxjs";
import { environment } from "./../../../../environments/environment";
import { io } from "socket.io-client";


@Injectable({
  providedIn: "root",
})
export class ChatToolService {
  constructor(private http: HttpClient) {
  }

  public message$: BehaviorSubject<any> = new BehaviorSubject('');
  public unread$: BehaviorSubject<any> = new BehaviorSubject('');

  sourceId = localStorage.getItem('partner_chatUserId');
  token = localStorage.getItem("partner_access_token");
  //Socket.io Client for chat module
  socket = io(environment.socket_url,{
    path:environment.socket_path,
    query: {
      userId: this.sourceId,
      authorization:this.token
    },
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 5000,
    reconnectionDelayMax: 5000
  });
  // socket = io(this.tempSocketUrl);

  public connection() {
    this.socket.emit('connection');
  }

  public sendMessageSocket(message: any) {
    this.socket.emit('send-message', message);
  }

  public unreadMessageSocket(json: any) {
    this.socket.emit('unread-message', json);
  }

  public unreadMessageSetZeroSocket(json: any) {
    this.socket.emit('unread-message-set-zero', json);
  }

  public createChannelSocket(json: any) {
    this.socket.emit('create-channel', json);
  }

  public addMemberSocket(json: any) {
    this.socket.emit('add-member', json);
  }

  public removeMemberSocket(json: any) {
    this.socket.emit('remove-member', json);
  }

  public getUnreadUpdates = (targetId:any) => {
    this.socket.on(`reciever-unread-result-${targetId}`, (unread) =>{
      this.unread$.next(unread);
    });

    return this.unread$.asObservable();
  };


  public getNewMessage = (targetId:any) => {
    this.socket.on(`reciever-message-result-${targetId}`, (message) =>{
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };

  //API Call Methods
  GetRpChatChannel(project_id: any,memberId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/get-rp-chat-channel?iProjectId=${project_id}&iRpMemberUuId=${memberId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  BindChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/partner/v2/bind-rp-chat-channel`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UnBindChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/partner/v2/unbind-rp-chat-channel`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  EditChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/edit-channel-name?iRpChannelUuId=${data.iRpChannelUuId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  UpdateChannelAdmin(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/update-channel-admin`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  LoginToChat(data: any): Observable<HttpResponse<any>> {
    return new Observable((observer) => {
      let apiURL = `${environment.mmUrl}/users/login`;

      this.http.post(apiURL, data, { observe: "response" }).subscribe(
        (res: HttpResponse<any>) => {
          // Success
          observer.next(res);
          observer.complete();
        },
        (error) => {
          console.error(error);
          observer.error(error);
        }
      );
    });
  }

  getAllPostsInChannel(page:any,limit:any,targetId: any,sourceId?: any) {
    sourceId=sourceId?sourceId:"";
    let iSelfChatId = localStorage.getItem('partner_chatUserId');
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-messages?iTargetId=${targetId}&iSourceId=${sourceId}&iSelfChatId=${iSelfChatId}&page=${page}&limit=${limit}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getAllPinnedPostsInChannel(targetId: any,sourceId?: any) {
    sourceId=sourceId?sourceId:"";
    let iSelfChatId = localStorage.getItem('partner_chatUserId');
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-messages?iTargetId=${targetId}&iSourceId=${sourceId}&isPinned=1&iSelfChatId=${iSelfChatId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getSideNavCategories(userId: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-sidebar-data?iRpMemberUuId=${userId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getMyUserInformation(iRpMemberUuId:any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-member-info?iRpMemberUuId=${iRpMemberUuId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  sendMessageToChannel(data: any) {
    const formData = new FormData();

    if(data.txChatAttachment){
    const files = [...data.txChatAttachment];

    for (let i = 0; i < files.length; i++) {
      formData.append(`txChatAttachment${i}`, files[i]);
    }
    }
      formData.append('iRecentMsgEpoch',data.iRecentMsgEpoch)
      formData.append('txChatMessage', data.txChatMessage);
      formData.append('iSourceId', data.iSourceId);
      formData.append('iTargetId', data.iTargetId);
      formData.append('isChannel',data.isChannel);
      formData.append('mentionedUsers',JSON.stringify(data.mentionedUsers));
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/send-message`;
      this.http
        .post(apiURL, formData)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  pinUnpinMessageToChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/pin-unpin-message`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  deleteMessageFromChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/delete-chat-message`;
      this.http
        .put(apiURL,data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getChannelInformation(channelId: any) {
    let iRpMemberUuId = localStorage.getItem('partner_chatUserId');
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-channel-info?iRpChannelUuId=${channelId}&iRpMemberUuId=${iRpMemberUuId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  editMessage(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/edit-chat-message`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  FindChannel(filterSearchValue: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/filter-channel?filter=${filterSearchValue}`;
      this.http
        .post(apiURL, '')
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  CreateMembers(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/create-member`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  getAllMembersForChat() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-members`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  CreateChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/add-channel-data`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllChannels() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-channels-data`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  RemoveMembersFromChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/remove-member-channel`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  AddMembersFromChannel(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/add-member-channel`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  EditUserName(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/edit-user-name?iRpMemberUuId=${data.iRpMemberUuId}`;
      this.http
        .put(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  VerifyUser(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/verify-channel-member`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetAllMembersForTag() {
    const promise = new Promise((resolve, reject) => {
      let apiURL = `${environment.baseUrl}/chat/v2/get-tag-member-info`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
