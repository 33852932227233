import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(@Inject(DOCUMENT) private doc: any) {}

  CreateLinkTagsForGoogleFont() {
    // link gstatic
    let gstaticLink: HTMLLinkElement = this.doc.createElement("link");
    gstaticLink.setAttribute("rel", "text/javascript");
    gstaticLink.setAttribute("href", "https://fonts.gstatic.com");
    this.doc.head.appendChild(gstaticLink);

    // link material icons
    let materialIconLink: HTMLLinkElement = this.doc.createElement("link");
    materialIconLink.setAttribute("rel", "stylesheet");
    materialIconLink.setAttribute("href", "https://fonts.googleapis.com/icon?family=Material+Icons");
    this.doc.head.appendChild(materialIconLink);

    // link font
    let fontLink: HTMLLinkElement = this.doc.createElement("link");
    fontLink.setAttribute("rel", "stylesheet");
    fontLink.setAttribute("href", "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
    this.doc.head.appendChild(fontLink);
  }

  CreateScriptTagForAmplitude() {
    let link: HTMLLinkElement = this.doc.createElement("script");
    link.setAttribute("type", "text/javascript");
    this.doc.head.appendChild(link);
    link.innerHTML = `
      (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
      ;r.type="text/javascript"
      ;r.integrity="sha384-+EO59vL/X7v6VE2s6/F4HxfHlK0nDUVWKVg8K9oUlvffAeeaShVBmbORTC2D3UF+"
      ;r.crossOrigin="anonymous";r.async=true
      ;r.src="https://cdn.amplitude.com/libs/amplitude-8.17.0-min.gz.js"
      ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
      console.log("[Amplitude] Error: could not load SDK")}}
      ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
      ;function s(e,t){e.prototype[t]=function(){
      this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
      var o=function(){this._q=[];return this}
      ;var a=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove"]
      ;for(var c=0;c<a.length;c++){s(o,a[c])}n.Identify=o;var u=function(){this._q=[]
      ;return this}
      ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
      ;for(var p=0;p<l.length;p++){s(u,l[p])}n.Revenue=u
      ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
      ;function v(e){function t(t){e[t]=function(){
      e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
      for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
      e=(!e||e.length===0?"$default_instance":e).toLowerCase()
      ;if(!Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])
      }return n._iq[e]};e.amplitude=n})(window,document);

      amplitude.getInstance().init("${environment.amplitude.api_key}");
    `;
  }

  CreateScriptTagForZendesk() {
    let link: HTMLLinkElement = this.doc.createElement("script");
    link.setAttribute("id", "ze-snippet");
    link.setAttribute("src", `https://static.zdassets.com/ekr/snippet.js?key=${environment.zendesk.api_key}`);
    this.doc.body.appendChild(link);
    link.innerHTML = `
      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: ${localStorage.getItem('partner_zendesk_token')}
            } 
          }
        }
      };
    `;
  }

  CreateScriptTagForGoogleTag() {
    if (environment.google_tag && environment.google_tag.gtag_id != "") {
      let link: HTMLLinkElement = this.doc.createElement("script");
      link.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${environment.google_tag.gtag_id}`);
      link.setAttribute("async", "");
      this.doc.body.appendChild(link);
      let data: HTMLLinkElement = this.doc.createElement("script");
      this.doc.body.appendChild(data);
      data.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.google_tag.gtag_id}');  
      `;
    }
  }
}
