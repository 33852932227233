import { ChangeDetectorRef, Injectable } from "@angular/core";
import { Permission } from "./../enums/permission.enum";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from 'crypto-js';
import { EventEmitterService } from "src/app/config/event-emitter.service";
import { HeaderService } from "src/app/partner/layouts/header/header.service";

const ENCRYPTION_KEY: string = "89701bb10aa7913";

@Injectable({
  providedIn: "root",
})
export class UserPermissionService {
  constructor(private http: HttpClient,private headerService: HeaderService,private eventEmitterService: EventEmitterService ,private router: Router, private cookieService: CookieService) { }

  // fill from API
  permissions: Permission[] = [
    // Default Permission
    Permission.AccessDashboard,

    // // Side Menu
    // Permission.AccessDashboard,
    // Permission.AccessContracts,
    // Permission.AccessEmployees,
    // Permission.AccessCalendar,
    // Permission.AccessInterviews,
    // Permission.AccessCompanyProfile,
    // Permission.AccessDealEngine,
    // Permission.AccessRoleManagement,
    // Permission.AccessProjects,
    // Permission.AccessRiskManagement,
    // Permission.AccessEscalationManagement,
    // Permission.AccessProjectFeedback,
    // Permission.AccessValueAndOpportunity,
    // Permission.AccessTaskManagement,
    // Permission.AccessTimesheet,
    // Permission.AccessWorkLogsApproval,
    // Permission.AccessHelpdesk,
    // Permission.AccessFileStorage,
    // Permission.AccessProjectReports,
    // Permission.AccessTMarket,
    // Permission.AccessProjectGrowth,
    // Permission.AccessProjectBilling,


    // // Dashboard
    // Permission.ViewDashboard,
    // Permission.ViewProfileAction,
    // Permission.ViewContractAction,
    // Permission.ViewEmployeeAction,
    // Permission.ViewInterviewAction,

    // // Contract
    // Permission.ViewContracts,
    // Permission.AccessIndividualContract,
    // Permission.SignContractAction,
    // Permission.UploadContractAction, //Closed

    // // Employee
    // Permission.ViewEmployees,
    // Permission.AddEmployeeAction,
    // Permission.EditEmployeeAction,
    // Permission.ResendEmployeeAction,
    // Permission.DeleteEmployeeAction,
    // Permission.EditEmployeePriceAction,

    // // Calendar
    // Permission.ViewCalendar,
    // Permission.ViewInterviewDetailsAction,
    // Permission.ViewContractDetailsAction,

    // // Interview
    // Permission.ViewInterviews,
    // Permission.AcceptAction,
    // Permission.ProposeNewInterviewTimeAction,
    // Permission.CancelInterviewAction,
    // Permission.AccessVideoConference,

    // // Company Profile
    // Permission.ViewCompanyProfile,
    // Permission.AccessEditCompanyProfile,
    // Permission.PublishCompanyProfileAction,
    // Permission.EditPrimaryDetailsAction,
    // Permission.EditExecutiveDetailsAction,
    // Permission.EditAdditionalDetailsAction,
    // Permission.EditCompanyDetailsAction,
    // Permission.EditCompanyDocumentsAction,
    // Permission.EditCertificationsAction,

    // // Deal Engine
    // Permission.ViewDealEngine,
    // Permission.ParticipateInDealAction,
    // Permission.ViewDealDetailsAction,
    // Permission.ViewCommentInDealAction,

    // // Role Management
    // Permission.ViewRoleManagement,
    // Permission.AddSubUserAction,
    // Permission.EditSubUserAction,
    // Permission.DeleteSubUserAction,
    // Permission.ResendSubUserInviteLinkAction,
    // Permission.ActivateSubUserAction,
    // Permission.DeactivateSubUserAction,

    // // Project Management
    // // Projects
    // Permission.CreateProjectAction,
    // Permission.ViewProjects,
    // Permission.ViewProjectsInTrashAction,
    // Permission.DeleteProjectAction,
    // Permission.RestoreProjectAction,
    // Permission.ViewProjectDetails,
    // Permission.UpdateStackholderDetails,
    // Permission.EditProjectDetailsAction,
    // Permission.AddMilestoneAction,
    // Permission.ChangeMilestoneOrderAction,
    // Permission.RemoveMilestoneOrderAction,
    // Permission.EditAssigneeListAction,
    // Permission.ViewMilestoneDetails,
    // Permission.EditMilestoneDetailsAction,
    // Permission.AddTaskAction,
    // Permission.DeleteTaskAction,
    // Permission.ViewTaskDetails,


    // // Risk Management
    // Permission.AddRiskAction,
    // Permission.ViewRiskAction,
    // Permission.DeleteRiskAction,
    // Permission.EditRiskAction,

    // // Escalation Management
    // Permission.ViewEscalationAction,
    // Permission.EditEscalationAction,
    // Permission.AddEscalationAction,
    // Permission.DeleteEscalationAction,

    // // Project Feedback
    // Permission.ViewFeedbacks,
    // Permission.AddFeedback,
    // Permission.EditFeedback,
    // Permission.DeleteFeedback,

    // // Value Or Opportunity
    // Permission.AddValueOrOpportunityAction,
    // Permission.ViewValueOrOpportunityAction,
    // Permission.EditValueOrOpportunityAction,
    // Permission.DeleteValueOrOpportunityAction,

    // // Task Management
    // Permission.ViewTasksProjectWise,
    // Permission.EditTaskDetailsAction,

    // // Timesheet
    // Permission.ViewProjectWiseTimesheet,
    // Permission.DownloadTimesheetCSVAction,

    // // Work Logs Approval
    // Permission.ViewWorklogs,
    // Permission.ApproveRejectWorklogAction,

    // // Helpdesk
    // Permission.AddSupportTicketAction,
    // Permission.ViewSupportTicket,
    // Permission.EditSupportTicketAction,

    // // File Storage
    // Permission.ViewFileStorage,
    // Permission.UploadAFileAction,
    // Permission.DeleteAFileAction,

    // // ProjectReports
    // Permission.ViewProjectReport,

    // //Project Growth
    // Permission.ViewProjectGrowth,
    // Permission.SubmitGrowthReportAction,

    // //TMarket
    // Permission.ViewProductsIntMarket,
    // Permission.AddProductIntMarketAction,
    // Permission.ViewtMarketProductDetailsAction,
    // Permission.UpdatetMarketProductDetailsAction,
    // Permission.DeleteProductFromtMarketAction,
    // Permission.ViewtMarketSalesEnquiriesAction,
    // Permission.ViewEnquiryTicketAction,
    // Permission.UpdateEnquiryTicketAction,

    // // Project Billing
    // Permission.ViewPmsBilling,
    // Permission.CreateOrUpdatePmsBillingAction,
  ];

  public isAuthorizedFor(permission: Permission): boolean {
    return this.permissions.indexOf(permission) >= 0;
  }

  ResetPermissions() {
    this.permissions = [
      // Default Permission
      Permission.AccessDashboard,
    ];
  }

  GetEncryptionKey() {
    return ENCRYPTION_KEY;
  }

  GetCookieData() {
    if (this.cookieService.check("partner_user")) {
      let cookieData = this.cookieService.get("partner_user");
      const bytes = CryptoJS.AES.decrypt(cookieData, this.GetEncryptionKey());
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
  }

  AssignUserPermissions() {
    let userCookie = this.GetCookieData();
    let userType = localStorage.getItem("partner_account_type");
    let userEmail = localStorage.getItem("partner_email");
    let subUserRoleId = localStorage.getItem("sub_partner_role");
    let subUserType = localStorage.getItem("sub_partner_account_type");
    let subUserEmail = localStorage.getItem("sub_partner_email");
    let partner_chatUserId = localStorage.getItem("partner_chatUserId");

    
    this.ResetPermissions();

    if (subUserRoleId && subUserType && subUserEmail) {
      if (!userCookie || userCookie.loggedInUser != 2 || subUserRoleId != userCookie.roleId || subUserType != userCookie.userType || subUserEmail != userCookie.userEmail || userType != userCookie.userType || userEmail != userCookie.userEmail  || partner_chatUserId != userCookie.chatId) {
        localStorage.removeItem("partner_access_token");
        localStorage.removeItem("partner_account_type");
        localStorage.removeItem("partner_email");
        localStorage.removeItem("sub_partner_account_type");
        localStorage.removeItem("sub_partner_email");
        localStorage.removeItem("sub_partner_role");
        localStorage.removeItem("partner_zendesk_token");
        localStorage.removeItem("partner_notification_token");
        localStorage.removeItem("partner_chatUserId");
        localStorage.removeItem("partner_path");
        this.cookieService.delete("partner_user");
        this.router.navigate(["login"]);
        return false;
      }

      let reqJSON = {
        roleId: subUserRoleId,
        userType: subUserType,
      };
      // this.GetUserPermissions(reqJSON).then((result: any) => {
      //   if (result.code == 200) {
      //     result.data.userFeatures.forEach((parentItem: any) => {
      //       this.FillParentPermission(parentItem.vParentFeature);
      //     });
      //     result.data.userFeatures.forEach((parentItem: any) => {
      //       parentItem.child.forEach((childItem: any) => {
      //         this.FillChildPermission(childItem.vChildFeature);
      //       });
      //     });


      //     if (this.router.url == "/login") {
      //       this.router.navigate(["partner"]);
      //     }
      //   }
      // });

      const promises: Promise<void>[] = [];
        const promise = new Promise<void>((resolve) => {
          this.GetUserPermissions(reqJSON).then((result: any) => {
            if (result.code == 200) {
              result.data.userFeatures.forEach((parentItem: any) => {
                this.FillParentPermission(parentItem.vParentFeature);
              });
              result.data.userFeatures.forEach((parentItem: any) => {
                parentItem.child.forEach((childItem: any) => {
                  this.FillChildPermission(childItem.vChildFeature);
                });
              });


              if (this.router.url == "/login") {
                this.router.navigate(["partner"]);
              }
              resolve(); 
            }
          });
        });
        promises.push(promise);

        Promise.all(promises)
          .then(() => {
            this.headerService.updateNavItems(new Date().valueOf());
            this.eventEmitterService.EmitPemissionsLoaded();
          })
          .catch((error) => {
            console.log(error, "error");
          });
      return true;
    } else {
      if (!userCookie || userCookie.loggedInUser != 1 || userType != userCookie.userType || userEmail != userCookie.userEmail || partner_chatUserId != userCookie.chatId) {
        localStorage.removeItem("partner_access_token");
        localStorage.removeItem("partner_account_type");
        localStorage.removeItem("partner_email");
        localStorage.removeItem("sub_partner_account_type");
        localStorage.removeItem("sub_partner_email");
        localStorage.removeItem("sub_partner_role");
        localStorage.removeItem("partner_zendesk_token");
        localStorage.removeItem("partner_notification_token");
        localStorage.removeItem("partner_chatUserId");
        this.cookieService.delete("partner_user");
        this.router.navigate(["login"]);
        return false;
      }

      this.permissions = [];

      // push all permissions for main user login
      this.permissions.push(
        // Side Menu
        Permission.AccessDashboard,
        Permission.AccessContracts,
        Permission.AccessEmployees,
        Permission.AccessCalendar,
        Permission.AccessInterviews,
        Permission.AccessCompanyProfile,
        Permission.AccessDealEngine,
        Permission.AccessRoleManagement,
        Permission.AccessProjects,
        Permission.AccessRiskManagement,
        Permission.AccessEscalationManagement,
        Permission.AccessProjectFeedback,
        Permission.AccessValueAndOpportunity,
        Permission.AccessTaskManagement,
        Permission.AccessTimesheet,
        Permission.AccessWorkLogsApproval,
        Permission.AccessHelpdesk,
        Permission.AccessFileStorage,
        Permission.AccessProjectReports,
        Permission.AccessProjectGrowth,
        Permission.AccessTMarket,
        Permission.AccessProjectBilling,

        // Dashboard
        Permission.ViewDashboard,
        Permission.ViewProfileAction,
        Permission.ViewContractAction,
        Permission.ViewEmployeeAction,
        Permission.ViewInterviewAction,

        // Contract
        Permission.ViewContracts,
        Permission.AccessIndividualContract,
        Permission.SignContractAction,
        // Permission.UploadContractAction,

        // Employee
        Permission.ViewEmployees,
        Permission.AddEmployeeAction,
        Permission.EditEmployeeAction,
        Permission.ResendEmployeeAction,
        Permission.DeleteEmployeeAction,
        Permission.EditEmployeePriceAction,

        // Calendar
        Permission.ViewCalendar,
        Permission.ViewInterviewDetailsAction,
        Permission.ViewContractDetailsAction,

        // Interview
        Permission.ViewInterviews,
        Permission.AcceptAction,
        Permission.ProposeNewInterviewTimeAction,
        Permission.CancelInterviewAction,
        Permission.AccessVideoConference,

        // Company Profile
        Permission.ViewCompanyProfile,
        Permission.AccessEditCompanyProfile,
        Permission.PublishCompanyProfileAction,
        Permission.EditPrimaryDetailsAction,
        Permission.EditExecutiveDetailsAction,
        Permission.EditAdditionalDetailsAction,
        Permission.EditCompanyDetailsAction,
        Permission.EditCompanyDocumentsAction,
        Permission.EditCertificationsAction,

        // Deal Engine
        Permission.ViewDealEngine,
        Permission.ParticipateInDealAction,
        Permission.ViewDealDetailsAction,
        Permission.ViewCommentInDealAction,

        // Role Management
        Permission.ViewRoleManagement,
        Permission.AddSubUserAction,
        Permission.EditSubUserAction,
        Permission.DeleteSubUserAction,
        Permission.ResendSubUserInviteLinkAction,
        Permission.ActivateSubUserAction,
        Permission.DeactivateSubUserAction,

        // Project Management
        // Projects
        Permission.CreateProjectAction,
        Permission.ViewProjects,
        Permission.ViewProjectsInTrashAction,
        Permission.DeleteProjectAction,
        Permission.RestoreProjectAction,
        Permission.ViewProjectDetails,
        Permission.UpdateStackholderDetails,
        Permission.EditProjectDetailsAction,
        Permission.AddMilestoneAction,
        Permission.ChangeMilestoneOrderAction,
        Permission.RemoveMilestoneOrderAction,
        Permission.EditAssigneeListAction,
        Permission.ViewMilestoneDetails,
        Permission.EditMilestoneDetailsAction,
        Permission.AddTaskAction,
        Permission.DeleteTaskAction,
        Permission.ViewTaskDetails,


        // Risk Management
        Permission.AddRiskAction,
        Permission.ViewRiskAction,
        Permission.DeleteRiskAction,
        Permission.EditRiskAction,

        // Escalation Management
        Permission.ViewEscalationAction,
        Permission.EditEscalationAction,
        Permission.AddEscalationAction,
        Permission.DeleteEscalationAction,

        // Project Feedback
        Permission.ViewFeedbacks,
        Permission.AddFeedback,
        Permission.EditFeedback,
        Permission.DeleteFeedback,

        // Value Or Opportunity
        Permission.AddValueOrOpportunityAction,
        Permission.ViewValueOrOpportunityAction,
        Permission.EditValueOrOpportunityAction,
        Permission.DeleteValueOrOpportunityAction,

        // Task Management
        Permission.ViewTasksProjectWise,
        Permission.EditTaskDetailsAction,

        // Timesheet
        Permission.ViewProjectWiseTimesheet,
        Permission.DownloadTimesheetCSVAction,

        // Work Logs Approval
        Permission.ViewWorklogs,
        Permission.ApproveRejectWorklogAction,

        // Helpdesk
        Permission.AddSupportTicketAction,
        Permission.ViewSupportTicket,
        Permission.EditSupportTicketAction,

        // File Storage
        Permission.ViewFileStorage,
        Permission.UploadAFileAction,
        Permission.DeleteAFileAction,

        // ProjectReports
        Permission.ViewProjectReport,

        //Project Growth
        Permission.ViewProjectGrowth,
        Permission.SubmitGrowthReportAction,

        //TMarket
        Permission.ViewProductsIntMarket,
        Permission.AddProductIntMarketAction,
        Permission.ViewtMarketProductDetailsAction,
        Permission.UpdatetMarketProductDetailsAction,
        Permission.DeleteProductFromtMarketAction,
        Permission.ViewtMarketSalesEnquiriesAction,
        Permission.ViewEnquiryTicketAction,
        Permission.UpdateEnquiryTicketAction,

        // Project Billing
        Permission.ViewPmsBilling,
        Permission.CreateOrUpdatePmsBillingAction,
      );
      this.headerService.updateNavItems(new Date().valueOf());
      return true;
    }
  }

  FillParentPermission(vParentFeature: any) {
    switch (vParentFeature) {
      case "Dashboard":
        if (this.permissions.includes(Permission.AccessDashboard)) {
          // dont push dashboard permission
        } else {
          // push dashboard permission
          this.permissions.push(Permission.AccessDashboard);
        }
        break;
      case "Contracts":
        this.permissions.push(Permission.AccessContracts);
        this.permissions.push(Permission.ViewContractAction);
        break;
      case "Employees":
        this.permissions.push(Permission.AccessEmployees);
        this.permissions.push(Permission.ViewEmployeeAction);
        break;
      case "Calendar":
        this.permissions.push(Permission.AccessCalendar);
        break;
      case "Interviews":
        this.permissions.push(Permission.AccessInterviews);
        this.permissions.push(Permission.ViewInterviewAction);
        break;
      case "Company Profile":
        this.permissions.push(Permission.AccessCompanyProfile);
        this.permissions.push(Permission.ViewProfileAction);
        break;
      case "Deal Engine":
        this.permissions.push(Permission.AccessDealEngine);
        break;
      case "Role Management":
        this.permissions.push(Permission.AccessRoleManagement);
        break;
      case "Projects":
        this.permissions.push(Permission.AccessProjects);
        this.permissions.push(Permission.ViewProjects);
        break;
      case "Risk Management":
        this.permissions.push(Permission.AccessRiskManagement);
        this.permissions.push(Permission.ViewRiskAction);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Escalation Management":
        this.permissions.push(Permission.AccessEscalationManagement);
        this.permissions.push(Permission.ViewEscalationAction);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Feedback":
        this.permissions.push(Permission.AccessProjectFeedback);
        this.permissions.push(Permission.ViewFeedbacks);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Value & Opportunity":
        this.permissions.push(Permission.AccessValueAndOpportunity);
        this.permissions.push(Permission.ViewValueOrOpportunityAction);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Task Management":
        this.permissions.push(Permission.AccessTaskManagement);
        this.permissions.push(Permission.ViewTasksProjectWise);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Timesheet":
        this.permissions.push(Permission.AccessTimesheet);
        this.permissions.push(Permission.ViewProjectWiseTimesheet);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Work Logs Approval":
        this.permissions.push(Permission.AccessWorkLogsApproval);
        this.permissions.push(Permission.ViewWorklogs);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Helpdesk":
        this.permissions.push(Permission.AccessHelpdesk);
        this.permissions.push(Permission.ViewSupportTicket);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "File Storage":
        this.permissions.push(Permission.AccessFileStorage);
        this.permissions.push(Permission.ViewFileStorage);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Reports":
        this.permissions.push(Permission.AccessProjectReports);
        this.permissions.push(Permission.ViewProjectReport);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Growth":
        this.permissions.push(Permission.AccessProjectGrowth);
        this.permissions.push(Permission.ViewProjectGrowth);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "Project Billing":
        this.permissions.push(Permission.AccessProjectBilling);
        this.permissions.push(Permission.ViewPmsBilling);
        if (this.permissions.includes(Permission.AccessProjects)) {
        } else {
          this.permissions.push(Permission.AccessProjects);
        }
        if (this.permissions.includes(Permission.ViewProjects)) {
        } else {
          this.permissions.push(Permission.ViewProjects);
        }
        break;
      case "T Market":
        this.permissions.push(Permission.AccessTMarket);
        this.permissions.push(Permission.ViewProductsIntMarket);
        break;
      default:
        this.permissions.push(Permission.AccessDashboard);
    }
  }

  FillChildPermission(vChildFeature: any) {
    switch (vChildFeature) {
      case "View Dashboard":
        this.permissions.push(Permission.ViewDashboard);
        break;
      case "View Dashboard Profile":
        this.permissions.push(Permission.ViewProfileAction);
        break;
      case "View Dashboard Contract":
        this.permissions.push(Permission.ViewContractAction);
        break;
      case "View Dashboard Employee":
        this.permissions.push(Permission.ViewEmployeeAction);
        break;
      case "View Dashboard Interview":
        this.permissions.push(Permission.ViewInterviewAction);
        break;
      case "View Contracts":
        this.permissions.push(Permission.ViewContracts);
        break;
      case "View Contract Details":
        this.permissions.push(Permission.AccessIndividualContract);
        break;
      // case "Upload Contract":
      //   this.permissions.push(Permission.UploadContractAction);
      //   break;
      case "Sign Contract":
        this.permissions.push(Permission.SignContractAction);
        break;
      case "View Employees":
        this.permissions.push(Permission.ViewEmployees);
        break;
      case "Add Employee":
        this.permissions.push(Permission.AddEmployeeAction);
        break;
      case "Edit Employee":
        this.permissions.push(Permission.EditEmployeeAction);
        break;
      case "Resend Invite Link":
        this.permissions.push(Permission.ResendEmployeeAction);
        break;
      case "Delete Employee":
        this.permissions.push(Permission.DeleteEmployeeAction);
        break;
      case "Edit Employee Price":
        this.permissions.push(Permission.EditEmployeePriceAction);
        break;
      case "View Calendar":
        this.permissions.push(Permission.ViewCalendar);
        break;
      case "View Interview Details in Calendar":
        this.permissions.push(Permission.ViewInterviewDetailsAction);
        break;
      case "View Contract Details in Calendar":
        this.permissions.push(Permission.ViewContractDetailsAction);
        break;
      case "View Interviews":
        this.permissions.push(Permission.ViewInterviews);
        break;
      case "Accept Interview":
        this.permissions.push(Permission.AcceptAction);
        break;
      case "Propose New Interview Time":
        this.permissions.push(Permission.ProposeNewInterviewTimeAction);
        break;
      case "Cancel Interview":
        this.permissions.push(Permission.CancelInterviewAction);
        break;
      case "Join Video Conference":
        if (this.permissions.includes(Permission.AccessInterviews)) {
          this.permissions.push(Permission.AccessVideoConference);
        }
        break;
      case "View Company Profile":
        this.permissions.push(Permission.ViewCompanyProfile);
        break;
      case "Edit Company Profile":
        this.permissions.push(Permission.AccessEditCompanyProfile);
        break;
      case "Publish Company Profile":
        this.permissions.push(Permission.PublishCompanyProfileAction);
        break;
      case "Edit Primary Details":
        this.permissions.push(Permission.EditPrimaryDetailsAction);
        break;
      case "Edit Executive Details":
        this.permissions.push(Permission.EditExecutiveDetailsAction);
        break;
      case "Edit Additional Details":
        this.permissions.push(Permission.EditAdditionalDetailsAction);
        break;
      case "Edit Company Details":
        this.permissions.push(Permission.EditCompanyDetailsAction);
        break;
      case "Edit Company Documents":
        this.permissions.push(Permission.EditCompanyDocumentsAction);
        break;
      case "Edit Certification Details":
        this.permissions.push(Permission.EditCertificationsAction);
        break;
      case "View Deal Engine":
        this.permissions.push(Permission.ViewDealEngine);
        break;
      case "Participate In Deals":
        this.permissions.push(Permission.ParticipateInDealAction);
        break;
      case "View Deal Details":
        this.permissions.push(Permission.ViewDealDetailsAction);
        break;
      case "View Comments In Deal":
        this.permissions.push(Permission.ViewCommentInDealAction);
        break;
      case "View Role Management":
        this.permissions.push(Permission.ViewRoleManagement);
        break;
      case "Add Sub User":
        this.permissions.push(Permission.AddSubUserAction);
        break;
      case "Edit Sub User":
        this.permissions.push(Permission.EditSubUserAction);
        break;
      case "Delete Sub User":
        this.permissions.push(Permission.DeleteSubUserAction);
        break;
      case "Resend Sub User Invite Link":
        this.permissions.push(Permission.ResendSubUserInviteLinkAction);
        break;
      case "Activate Sub User":
        this.permissions.push(Permission.ActivateSubUserAction);
        break;
      case "Deactivate Sub User":
        this.permissions.push(Permission.DeactivateSubUserAction);
        break;
      case "Create Project":
        this.permissions.push(Permission.CreateProjectAction);
        break;
      case "View Projects":
        this.permissions.push(Permission.ViewProjects);
        break;
      case "View Projects In Trash":
        this.permissions.push(Permission.ViewProjectsInTrashAction);
        break;
      case "Delete Project":
        this.permissions.push(Permission.DeleteProjectAction);
        break;
      case "Restore Project":
        this.permissions.push(Permission.RestoreProjectAction);
        break;
      case "View Project Details":
        this.permissions.push(Permission.ViewProjectDetails);
        break;
      case "Update Stakeholder Details":
        this.permissions.push(Permission.UpdateStackholderDetails);
        break;
      case "Edit Project Details":
        this.permissions.push(Permission.EditProjectDetailsAction);
        break;
      case "Add Milestone":
        this.permissions.push(Permission.AddMilestoneAction);
        break;
      case "Change Milestone Order":
        this.permissions.push(Permission.ChangeMilestoneOrderAction);
        break;
      case "Remove Milestone From Project":
        this.permissions.push(Permission.RemoveMilestoneOrderAction);
        break;
      case "Edit Assignee List":
        this.permissions.push(Permission.EditAssigneeListAction);
        break;
      case "View Milestone Details":
        this.permissions.push(Permission.ViewMilestoneDetails);
        break;
      case "Edit Milestone Details":
        this.permissions.push(Permission.EditMilestoneDetailsAction);
        break;
      case "Add Task":
        this.permissions.push(Permission.AddTaskAction);
        break;
      case "Delete Task":
        this.permissions.push(Permission.DeleteTaskAction);
        break;
      case "View Task Details":
        this.permissions.push(Permission.ViewTaskDetails);
        break;
      case "Add Risk":
        this.permissions.push(Permission.AddRiskAction);
        break;
      case "View Risk":
        this.permissions.push(Permission.ViewRiskAction);
        break;
      case "Delete Risk":
        this.permissions.push(Permission.DeleteRiskAction);
        break;
      case "Edit Risk":
        this.permissions.push(Permission.EditRiskAction);
        break;
      case "View Escalation":
        this.permissions.push(Permission.ViewEscalationAction);
        break;
      case "Edit Escalation":
        this.permissions.push(Permission.EditEscalationAction);
        break;
      case "Add Escalation":
        this.permissions.push(Permission.AddEscalationAction);
        break;
      case "Delete Escalation":
        this.permissions.push(Permission.DeleteEscalationAction);
        break;
      case "View Feedbacks":
        this.permissions.push(Permission.ViewFeedbacks);
        break;
      case "Add Feedback":
      this.permissions.push(Permission.AddFeedback);
      break;
      case "Edit Feedback":
        this.permissions.push(Permission.EditFeedback);
        break;
      case "Delete Feedback":
        this.permissions.push(Permission.DeleteFeedback);
        break;
      case "Add Value Or Opportunity":
        this.permissions.push(Permission.AddValueOrOpportunityAction);
        break;
      case "View Value Or Opportunity":
        this.permissions.push(Permission.ViewValueOrOpportunityAction);
        break;
      case "Edit Value Or Opportunity":
        this.permissions.push(Permission.EditValueOrOpportunityAction);
        break;
      case "Delete Value Or Opportunity":
        this.permissions.push(Permission.DeleteValueOrOpportunityAction);
        break;
      case "View Tasks Project Wise":
        this.permissions.push(Permission.ViewTasksProjectWise);
        break;
      case "Edit Task Details":
        this.permissions.push(Permission.EditTaskDetailsAction);
        break;
      case "View Project Wise Timesheet":
        this.permissions.push(Permission.ViewProjectWiseTimesheet);
        break;
      case "Download Timesheet CSV":
        this.permissions.push(Permission.DownloadTimesheetCSVAction);
        break;
      case "View Worklogs":
        this.permissions.push(Permission.ViewWorklogs);
        break;
      case "Approve/Reject Worklog":
        this.permissions.push(Permission.ApproveRejectWorklogAction);
        break;
      case "Add Support Ticket":
        this.permissions.push(Permission.AddSupportTicketAction);
        break;
      case "View Support Ticket":
        this.permissions.push(Permission.ViewSupportTicket);
        break;
      case "Edit Support Ticket":
        this.permissions.push(Permission.EditSupportTicketAction);
        break;
      case "View FileStorage":
        this.permissions.push(Permission.ViewFileStorage);
        break;
      case "Upload A File":
        this.permissions.push(Permission.UploadAFileAction);
        break;
      case "Delete A File":
        this.permissions.push(Permission.DeleteAFileAction);
        break;
      case "View Project Report":
        this.permissions.push(Permission.ViewProjectReport);
        break;
      case "View Products In tMarket":
        this.permissions.push(Permission.ViewProductsIntMarket);
        break;
      case "View tMarket Product Details":
        this.permissions.push(Permission.ViewtMarketProductDetailsAction);
        break;
      case "Add Product In tMarket":
        this.permissions.push(Permission.AddProductIntMarketAction);
        break;
      case "Update tMarket Product Details":
        this.permissions.push(Permission.UpdatetMarketProductDetailsAction);
        break;
      case "Delete Product From tMarket":
        this.permissions.push(Permission.DeleteProductFromtMarketAction);
        break;
      case "View tMarket Sales Enquiries":
        this.permissions.push(Permission.ViewtMarketSalesEnquiriesAction);
        break;
      case "View Enquiry Ticket":
        this.permissions.push(Permission.ViewEnquiryTicketAction);
        break;
      case "Update Enquiry Ticket":
        this.permissions.push(Permission.UpdateEnquiryTicketAction);
        break;
      case "View Growth":
        this.permissions.push(Permission.ViewProjectGrowth);
        break;
      case "Submit Report":
        this.permissions.push(Permission.SubmitGrowthReportAction);
        break;
      case "View Billing In Pms":
        this.permissions.push(Permission.ViewPmsBilling);
        break;
      case "Create/Update Invoice In Pms":
        this.permissions.push(Permission.CreateOrUpdatePmsBillingAction);
        break;
    }
  }
  
  GetRoles(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-roles/${data.userType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetUserPermissions(data: any) {
    const promise = new Promise((resolve, reject) => {
      let apiURL = environment.baseUrl + `/common/v2/get-user-feature?iRoleId=${data.roleId}&tiUserFeatureType=${data.userType}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
}
