import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class HeaderService {

  // Detect Perfect Scroll End
  public scrollEnd = new Subject<boolean>();

  public scrollEnd$ = this.scrollEnd.asObservable(); //Has a $

  public updateScrollSubject(newScrollEnd: boolean) {
    this.scrollEnd.next(newScrollEnd);
  }

  public navCount = new Subject<number>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public navCount$ = this.navCount.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateNavItems(newnavCount: number) {
    this.navCount.next(newnavCount);
  }

  public innerNav = new Subject<any[]>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public innerNav$ = this.innerNav.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateInnerNav(list: any) {
    this.innerNav.next(list);
  }

  constructor(private http: HttpClient,private modalService: NgbModal) {}

  Signup(data: any) {
    return this.http.post(`${environment.baseUrl}/partner/v1/signup`, data);
  }

  SkillList() {
    return this.http.get(`${environment.baseUrl}/common/v1/skillsList`);
  }

  ResendEmailVerification(data: any) {
    return this.http.post(`${environment.baseUrl}/partner/v1/resendEmailVerification`, data);
  }

  EmployeeList(skill: any) {
    return this.http.get(`${environment.baseUrl}/employee/v1/employee-list?skill=${skill}`);
  }

  loginUserInformation(data: any) {
    return this.http.get(`${environment.baseUrl}/common/v1/login-user-information`, data);
  }

  logoutAPI(data: any) {
    return this.http.post(`${environment.baseUrl}/common/v1/Logout`, data);
  }

  Logout() {
    localStorage.removeItem("partner_access_token");
    localStorage.removeItem("partner_account_type");
    localStorage.removeItem("partner_email");
    localStorage.removeItem("sub_partner_account_type");
    localStorage.removeItem("sub_partner_email");
    localStorage.removeItem("sub_partner_role");
    localStorage.removeItem("partner_zendesk_token");
    localStorage.removeItem("partner_notification_token");
    localStorage.removeItem("partner_chatUserId");
    localStorage.removeItem("partner_path");
    this.modalService.dismissAll();
  }

  ContactUs(data: any) {
    return this.http.post(`${environment.baseUrl}/common/v1/contact-us`, data);
  }

  SubscriptionRequest(data: any) {
    return this.http.post(`${environment.baseUrl}/common/v1/subscription-request`, data);
  }

  ClientSignup(data: any) {
    return this.http.post(`${environment.baseUrl}/client/v1/signup`, data);
  }

  PartnerNewSignup(data: any) {
    return this.http.post(`${environment.baseUrl}/partner/v1/new-signup`, data);
  }

  SubscriptionPlan() {
    return this.http.get(`${environment.baseUrl}/common/v1/subscription-plan`);
  }

  GetNotification(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/notification/${data.iUserId}`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  NotificationChecked(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v1/notification/checked`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetInterviewCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/partner/v1/get-interview-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetContractCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/partner/v1/get-contract-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetPMSCount() {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/partner/v2/project-count`;
      this.http
        .get(apiURL)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

  GetLocalTimeZone(){
    const localtimezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    return localtimezone;
  }

  LoginSubUserInformation(data: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = `${environment.baseUrl}/common/v2/login-sub-user-information`;
      this.http
        .post(apiURL, data)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }

}
