import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GrowthService } from './growth.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/config/notification.service';
import { environment } from 'src/environments/environment';
import { Permission } from "./../../../utils/enums/permission.enum";
import { UserPermissionService } from "./../../../utils/services/user-permission.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-project-growth',
  templateUrl: './project-growth.component.html',
  styles: [
  ]
})

export class ProjectGrowthComponent implements OnInit {
  submitGrowthReportActionPermission: boolean = false;
  imagePath = environment.image_path;

  @ViewChild(MatTableExporterDirective) matTableExporter!: MatTableExporterDirective;

  public canvasWidthNps: any;
  public needleValueNps: any;
  public centralLabelNps: any;
  public optionsNps: any;
  public barWidthNps: any;

  public canvasWidthCsat: any;
  public needleValueCsat: any;
  public centralLabelCsat: any;
  public optionsCsat: any;
  public barWidthCsat: any;

  projectId: any;
  getAllProjects: any = [];
  projectFlag: boolean = false;
  vParameters: any = [];
  name = 'Angular 5';

  displayedColumns = ['iCreatedAt', 'vValueAdditionTitle','vResponsibleBy','tiOpportunityStatus','txValueAdditionDescription'];
  dataSource: any = new MatTableDataSource();

  DeliveryForm!: FormGroup;
  DeliveryFormSubmitted: any = false;

  ExperienceForm!: FormGroup;
  ExperienceFormSubmitted: any = false;

  WSRForm!: FormGroup;
  WSRFormSubmitted: any = false;

  growthData: any = [];
  CsatArray: any = [];
  NpsArray: any = [];
  WsrArray: any = [];

  selectAllCsat: boolean = false;
  selectAllNps: boolean = false;
  selectAllWsr: boolean = false;

  GrowthUniqueId: string | undefined;

  //DatatableReport Parameters

  csatRequired:boolean=false;
  npsRequired:boolean=false;
  wsrRequired:boolean=false;


  csatAllParameter: any = [];
  npsAllParameter: any = [];
  wsrAllParameter: any = [];


  csatReportArray: any = []
  npsReportArray: any = []
  wsrReportArray: any = []

  // GrowthUniqueId: string | undefined;
  tiPmsGrowthType: number | undefined;
  ChartDataCsat: any;
  ChartDataNps: any;


  tableDataCSAT: any = {};
  tableDataNPS: any = {};
  tableDataWSR: any = {};
  parametersInFirstColumnCSAT: any = [];
  parametersInFirstColumnNPS: any = [];
  parametersInFirstColumnWSR: any = [];

  uniqueParametersCSAT: string[] = [];
  uniqueParametersNPS: string[] = [];
  uniqueParametersWSR: string[] = [];

  uniqueDatesCSAT: string[] = [];
  uniqueDatesNPS: string[] = [];
  uniqueDatesWSR: string[] = [];
  OppotunityData: undefined | any[];
  isdivShow: boolean = false;
    
    constructor(private modalService: NgbModal,private userPermissionService: UserPermissionService, public growth: GrowthService, private notifyService: NotificationService, private activatedRoute: ActivatedRoute,) {

    // assign individual permissions
    const SubmitGrowthReportAction: Permission = Permission.SubmitGrowthReportAction;

    // // check what user is authorized
    if (this.userPermissionService.isAuthorizedFor(SubmitGrowthReportAction)) {
      this.submitGrowthReportActionPermission = true;
    }

  }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.paramMap.get("projectId");
    if (this.projectId) {
      this.projectFlag = true;
    }

    this.canvasWidthNps = 500;
    this.needleValueNps = 60;
    this.centralLabelNps = '';
    this.optionsNps = {
      hasNeedle: true,
      needleColor: 'grayblack',
      needleUpdateSpeed: 800,
      arcColors: [
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
      ],
      arcDelimiters: [10, 20, 30, 40, 50, 60, 70, 80, 90],
      arcLabel: [10, 20, 30],
      rangeLabel: ['0', '100'],
      needleStartValue: 0,
      arcLabels: ['10', '20', '30', '40', '50', '60', '70', '80', '90'],
      arcpadding: 5,
    };

    this.canvasWidthCsat = 500;
    this.needleValueCsat = 60;
    this.centralLabelCsat = '';
    this.optionsCsat = {
      hasNeedle: true,
      needleColor: 'grayblack',
      needleUpdateSpeed: 800,
      arcColors: [
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
        '#78D6C6',
      ],
      arcDelimiters: [10, 20, 30, 40, 50, 60, 70, 80, 90],
      arcLabel: [10, 20, 30],
      rangeLabel: ['0', '100'],
      needleStartValue: 0,
      arcLabels: ['10', '20', '30', '40', '50', '60', '70', '80', '90'],
      arcpadding: 5,
    };

    this.DeliveryForm = new FormGroup({
      txComment: new FormControl(""),
    });

    this.ExperienceForm = new FormGroup({
      txComment: new FormControl(""),
    });

    this.WSRForm = new FormGroup({
      txComment: new FormControl(""),
    });

    this.getGrowthNames();
    this.GetAllProjects();
  }

  GetReportDataByProjectId() {
    this.selectAllCsat = false;
    this.selectAllNps = false;
    this.selectAllWsr = false;
    this.GrowthUniqueId = undefined;
    this.csatAllParameter = [];
    this.npsAllParameter = [];
    this.wsrAllParameter = [];
    this.csatReportArray = []
    this.npsReportArray = []
    this.wsrReportArray = []
    this.tableDataCSAT = {};
    this.tableDataNPS = {};
    this.tableDataWSR = {};
    this.parametersInFirstColumnCSAT = [];
    this.parametersInFirstColumnNPS = [];
    this.parametersInFirstColumnWSR = [];
    this.uniqueParametersCSAT = [];
    this.uniqueParametersNPS = [];
    this.uniqueParametersWSR = [];
    this.uniqueDatesCSAT = [];
    this.uniqueDatesNPS = [];
    this.uniqueDatesWSR = [];
    this.csatRequired=false;
    this.npsRequired=false;
    this.wsrRequired=false;
    this.DeliveryForm.controls['txComment'].setValue("");
    this.CsatArray.forEach((obj: any) => obj.tiPmsGrowth = false);
    this.ExperienceForm.controls['txComment'].setValue("");
    this.NpsArray.forEach((obj: any) => obj.tiPmsGrowth = false);
    this.WSRForm.controls['txComment'].setValue("");
    this.WsrArray.forEach((obj: any) => obj.tiPmsGrowth = false);


    this.GetAllReportParameters();
    this.GetAllReportData();
  }


  GetAllProjects() {
    this.growth.GetAllProjects().then((result: any) => {
      if (result.code == 200) {
        this.getAllProjects = result['data'];
        this.getAllProjects.unshift({
          iProjectId: 0,
          vProjectName: "Overall Growth"
        });
        if (this.projectFlag === false) {
          if (!this.projectId) {
            this.projectId = this.getAllProjects[0].iProjectId;
          }
          this.GetProjectByFilter();
        } else {
          this.projectId = Number(this.projectId);
          this.GetProjectByFilter();
        }
      }
    });
  }

  GetAllReportParameters() {
    this.growth.GetAllReportParameters().then((result: any) => {
      if (result.code == 200) {
        this.csatAllParameter = result['data'].pmsGrowthCsatArray;
        this.npsAllParameter = result['data'].pmsGrowthNpsArray;
        this.wsrAllParameter = result['data'].pmsGrowthWsrArray;

      } else {
        this.notifyService.showError(result.message, "");

      }
    });
  }

  GetCSATReportData() {
    this.growth.GetCSATReportData(this.projectId).then((result: any) => {
      if (result.code == 200) {
        this.csatReportArray = result['data'];
        this.initializeParameters(this.csatAllParameter, 1);
        this.uniqueDatesCSAT = this.getUniqueDates(this.csatReportArray);
        // Preprocess the data
        this.processData(this.csatReportArray, 1);
        this.calculateUniqueParameters(1);
        this.calculateUniqueDates(1);
      } else {
        this.notifyService.showError(result.message, "");

      }
    });
  }

  GetNPSReportData() {
    this.growth.GetNPSReportData(this.projectId).then((result: any) => {
      if (result.code == 200) {
        this.npsReportArray = result['data'];
        this.initializeParameters(this.npsAllParameter, 2);
        this.uniqueDatesNPS = this.getUniqueDates(this.npsReportArray);
        // Preprocess the data
        this.processData(this.npsReportArray, 2);
        this.calculateUniqueParameters(2);
        this.calculateUniqueDates(2);
      } else {
        this.notifyService.showError(result.message, "");

      }
    });
  }

  GetWSRReportData() {
    this.growth.GetWSRReportData(this.projectId).then((result: any) => {
      if (result.code == 200) {
        this.wsrReportArray = result['data'];
        this.initializeParameters(this.wsrAllParameter, 3);
        this.uniqueDatesWSR = this.getUniqueDates(this.wsrReportArray);

        // Preprocess the data
        this.processData(this.wsrReportArray, 3);
        this.calculateUniqueParameters(3);
        this.calculateUniqueDates(3);
      } else {
        this.notifyService.showError(result.message, "");

      }
    });
  }


  GetAllReportData() {
    this.GetCSATReportData();
    this.GetNPSReportData();
    this.GetWSRReportData();
  }


  GetProjectByFilter(event?: any) {
    this.projectId = event ? event.value : this.projectId;
    this.getGaugeChartData();
    this.OpptunityData();
    this.GetReportDataByProjectId();
  }

  SelectParameter(obj: any, flag: any) {
    let count = 0;
    obj.tiPmsGrowth = !obj.tiPmsGrowth;
    if (flag == 1) {
      this.CsatArray.forEach((item: any) => {
        if (item.tiPmsGrowth === true) {
          count++;
        }
      });
      if (count == this.CsatArray.length) { 
        this.selectAllCsat = true;
        this.csatRequired=true;
       } else {
         this.selectAllCsat = false ;
         if(count>0){
          this.csatRequired=true;
         }else{
          this.csatRequired=false;
         }
        }
    } else if (flag == 2) {
      this.NpsArray.forEach((item: any) => {
        if (item.tiPmsGrowth === true) {
          count++;
        }
      }); if (count == this.NpsArray.length) { 
        this.selectAllNps = true;
        this.npsRequired=true;
      } else { 
        this.selectAllNps = false;
        if(count>0){
          this.npsRequired=true;
         }else{
          this.npsRequired=false;
         }
      }
    } else if (flag == 3) {
      this.WsrArray.forEach((item: any) => {
        if (item.tiPmsGrowth === true) {
          count++;
        }
      }); if (count == this.WsrArray.length) { 
        this.selectAllWsr = true;
        this.wsrRequired=true;
      } else { 
        this.selectAllWsr = false;
        if(count>0){
          this.wsrRequired=true;
         }else{
          this.wsrRequired=false;
         }
       }
    }
  }

  getGrowthNames() {
    this.growth.getGrowthNames().then((result: any) => {
      this.CsatArray = result["data"].pmsGrowthCsatArray;
      this.NpsArray = result["data"].pmsGrowthNpsArray;
      this.WsrArray = result["data"].pmsGrowthWsrArray;

    });
  }

  selectCsatAll(selected: boolean) {
    this.selectAllCsat = selected;
    if (this.CsatArray == null) {
      return;
    }
    if(selected===true){
      this.csatRequired=true;
    }else{
      this.csatRequired=false;
    }
    this.CsatArray.forEach((obj: any) => obj.tiPmsGrowth = selected);
  }

  selectNpsAll(selected: boolean) {
    this.selectAllNps = selected;
    if (this.NpsArray == null) {
      return;
    }
    if(selected===true){
      this.npsRequired=true;
    }else{
      this.npsRequired=false;
    }
    this.NpsArray.forEach((obj: any) => obj.tiPmsGrowth = selected);
  }

  selectWsrAll(selected: boolean) {
    this.selectAllWsr = selected;
    if (this.WsrArray == null) {
      return;
    }
    if(selected===true){
      this.wsrRequired=true;
    }else{
      this.wsrRequired=false;
    }
    this.WsrArray.forEach((obj: any) => obj.tiPmsGrowth = selected);
  }


  SubmitReport(flag: any, hasPermission: boolean) {
    if (hasPermission) {
      this.GrowthUniqueId = this.projectId + Date.now();
      if (flag == 1) {
        if(!this.csatRequired){
          this.notifyService.showError("Kindly select at least one parameter to submit the report", "");
          return;
        }
        if (this.DeliveryForm.value.txComment.length > 600) {
          this.notifyService.showError("Comment length can not exceed 600 characters", "");
          return;
        }

        let tempArr = [...this.CsatArray];

        tempArr.push({ tiPmsGrowth: this.DeliveryForm.value.txComment.trim() ? true : false, vGrowthComment: this.DeliveryForm.value.txComment.trim() })
        let reqJson = {
          iProjectId: this.projectId,
          vGrowthUniqueId: this.GrowthUniqueId,
          tiGrowthReportType: 1,
          tiPmsGrowthType: 1,
          growthArray: tempArr,
        }

        this.growth.SubmitAPI(reqJson).then((result: any) => {
          if (result.code == 200) {
            this.GetAllProjects();
            this.notifyService.showSuccess(result.message, "");
          } else {
            this.notifyService.showError(result.message, "");
          }
        })
      } else if (flag == 2) {
        if(!this.npsRequired){
          this.notifyService.showError("Kindly select at least one parameter to submit the report", "");
          return;
        }
        if (this.ExperienceForm.value.txComment.length > 600) {
          this.notifyService.showError("Comment length can not exceed 600 characters", "");
          return;
        }
        let tempArr = [...this.NpsArray];

        tempArr.push({ tiPmsGrowth: this.ExperienceForm.value.txComment.trim() ? true : false, vGrowthComment: this.ExperienceForm.value.txComment.trim() })

        let reqJson = {
          iProjectId: this.projectId,
          vGrowthUniqueId: this.GrowthUniqueId,
          tiGrowthReportType: 2,
          tiPmsGrowthType: 2,
          growthArray: tempArr,
        }

        this.growth.SubmitAPI(reqJson).then((result: any) => {
          if (result.code == 200) {
            this.GetAllProjects();
            this.notifyService.showSuccess(result.message, "");
          } else {
            this.notifyService.showError(result.message, "");
          }
        })
      } else if (flag == 3) {
        if(!this.wsrRequired){
          this.notifyService.showError("Kindly select at least one parameter to submit the report", "");
          return;
        }
        if (this.WSRForm.value.txComment.length > 600) {
          this.notifyService.showError("Comment length can not exceed 600 characters", "");
          return;
        }
        let tempArr = [...this.WsrArray];

        tempArr.push({ tiPmsGrowth: this.WSRForm.value.txComment.trim() ? true : false, vGrowthComment: this.WSRForm.value.txComment.trim() })

        let reqJson = {
          iProjectId: this.projectId,
          vGrowthUniqueId: this.GrowthUniqueId,
          tiGrowthReportType: 3,
          tiPmsGrowthType: 3,
          growthArray: tempArr,
        }

        this.growth.SubmitAPI(reqJson).then((result: any) => {
          if (result.code == 200) {
            this.GetAllProjects();
            this.notifyService.showSuccess(result.message, "");
          } else {
            this.notifyService.showError(result.message, "");
          }
        })

      }
    } else {
      this.notifyService.showError("Not Authorized!", "");
    }
  }


  // Datatable Logic

  initializeParameters(list: any, flag: any): void {
    if (flag == 1) {
      this.parametersInFirstColumnCSAT = list.map((item: any) => item.vPmsGrowthName);
    } else if (flag == 2) {
      this.parametersInFirstColumnNPS = list.map((item: any) => item.vPmsGrowthName);
    } else if (flag == 3) {
      this.parametersInFirstColumnWSR = list.map((item: any) => item.vPmsGrowthName);
    }
  }


  processData(staticJson: any[], flag: any): void {
    for (const entry of staticJson) {
      const date = entry.date;
      for (const parameter of entry.parameters) {
        const vGrowthUniqueId = parameter.vGrowthUniqueId;
        for (const value of parameter.values) {
          const parameterName = value.vPmsGrowthName;
          const booleanValue = value.tiPmsGrowth === 1;
          let vGrowthComment;
          if (value.vGrowthComment) {
            vGrowthComment = value.vGrowthComment
          }

          if (flag == 1) {
            if (!this.tableDataCSAT[parameterName]) {
              this.tableDataCSAT[parameterName] = {};
            }

            if (!this.tableDataCSAT[parameterName][date]) {
              this.tableDataCSAT[parameterName][date] = {};
            }

            this.tableDataCSAT[parameterName][date][vGrowthUniqueId] = vGrowthComment ? vGrowthComment : booleanValue;

          } else if (flag == 2) {
            if (!this.tableDataNPS[parameterName]) {
              this.tableDataNPS[parameterName] = {};
            }

            if (!this.tableDataNPS[parameterName][date]) {
              this.tableDataNPS[parameterName][date] = {};
            }

            this.tableDataNPS[parameterName][date][vGrowthUniqueId] = vGrowthComment ? vGrowthComment : booleanValue;

          } else if (flag == 3) {
            if (!this.tableDataWSR[parameterName]) {
              this.tableDataWSR[parameterName] = {};
            }

            if (!this.tableDataWSR[parameterName][date]) {
              this.tableDataWSR[parameterName][date] = {};
            }

            this.tableDataWSR[parameterName][date][vGrowthUniqueId] = vGrowthComment ? vGrowthComment : booleanValue;

          }
        }
      }
    }
  }


  calculateUniqueParameters(flag: any): void {
    if (flag == 1) {
      this.uniqueParametersCSAT = Object.keys(this.tableDataCSAT);
    } else if (flag == 2) {
      this.uniqueParametersNPS = Object.keys(this.tableDataNPS);
    } else if (flag == 3) {
      this.uniqueParametersWSR = Object.keys(this.tableDataWSR);
    }
  }

  calculateUniqueDates(flag: any): void {
    if (flag == 1) {
      const allDates: any = Object.values(this.tableDataCSAT).map((dateGroup: any) => Object.keys(dateGroup));
      this.uniqueDatesCSAT = [...new Set([].concat(...allDates))];
    } else if (flag == 2) {
      const allDates: any = Object.values(this.tableDataNPS).map((dateGroup: any) => Object.keys(dateGroup));
      this.uniqueDatesNPS = [...new Set([].concat(...allDates))];
    } else if (flag == 3) {
      const allDates: any = Object.values(this.tableDataWSR).map((dateGroup: any) => Object.keys(dateGroup));
      this.uniqueDatesWSR = [...new Set([].concat(...allDates))];
    }
  }

  uniqueSubcolumnsForDate(date: string, flag: any) {
    const subcolumns: string[] = [];

    if (flag == 1) {

      for (const parameter of this.uniqueParametersCSAT) {
        if (
          this.tableDataCSAT[parameter] &&
          this.tableDataCSAT[parameter][date]
        ) {
          for (const subcolumn of Object.keys(this.tableDataCSAT[parameter][date])) {
            if (!subcolumns.includes(subcolumn)) {
              subcolumns.push(subcolumn);
            }
          }
        }
      }

    } else if (flag == 2) {

      for (const parameter of this.uniqueParametersNPS) {
        if (
          this.tableDataNPS[parameter] &&
          this.tableDataNPS[parameter][date]
        ) {
          for (const subcolumn of Object.keys(this.tableDataNPS[parameter][date])) {
            if (!subcolumns.includes(subcolumn)) {
              subcolumns.push(subcolumn);
            }
          }
        }
      }

    } else if (flag == 3) {

      for (const parameter of this.uniqueParametersWSR) {
        if (
          this.tableDataWSR[parameter] &&
          this.tableDataWSR[parameter][date]
        ) {
          for (const subcolumn of Object.keys(this.tableDataWSR[parameter][date])) {
            if (!subcolumns.includes(subcolumn)) {
              subcolumns.push(subcolumn);
            }
          }
        }
      }

    }
    return subcolumns;
  }

  getUniqueDates(arrayStatic: any): string[] {
    const uniqueDates: string[] = [];
    for (const entry of arrayStatic) {
      uniqueDates.push(entry.date);
    }

    // Remove duplicates and return the unique dates
    return Array.from(new Set(uniqueDates));

  }

  getGaugeChartData() {
    this.growth.getGaugeChartData(this.projectId).then((result: any) => {
      this.needleValueCsat = result["data"].npsData.csat;
      this.needleValueNps = result["data"].npsData.nps;
    });
  }

  OpptunityData() {
    this.growth.GetOppotunityData(this.projectId).then((result: any) => {
      this.dataSource = result["data"];

    });
  }

  descriptionText: any;
  ViewComment(modalToOpen: any, descriptionText: any) {
    this.descriptionText = descriptionText;
    this.modalService.open(modalToOpen, { centered: true, windowClass: "mileStone-modal" });
  }

  CloseModal() {
    this.modalService.dismissAll();
  }
}
