import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RouteGuardService } from "./route-guard.service";
import { DesignSystemComponent } from "./design-system/design-system.component";
import { UnderConstructionComponent } from "./partner/under-construction/under-construction.component";
import { SiteConstructionComponent } from "./site-construction/site-construction.component";
import { ProjectGrowthComponent } from "./partner/project-management/project-growth/project-growth.component";

const routes: Routes = [
  {
    path: "partner",
    canActivate: [RouteGuardService],
    loadChildren: () => import("./partner/partner.module").then((m) => m.PartnerModule),
  },
  { path: "design-system", component: DesignSystemComponent },
  {path: "project-growth", component: ProjectGrowthComponent },
  // { path: "", component: SiteConstructionComponent },
  { path: "login", loadChildren: () => import("./login/login.module").then((m) => m.LoginModule) },
  { path: "forgot-password", loadChildren: () => import("./forgot-password/forgot-password.module").then((m) => m.ForgotPasswordModule) },
  { path: "reset-password/:txEmailToken", loadChildren: () => import("./reset-password/reset-password.module").then((m) => m.ResetPasswordModule) },
  { path: "user-reset-password/:txEmailToken", loadChildren: () => import("./sub-user-reset-password/sub-user-reset-password.module").then((m) => m.SubUserResetPasswordModule) },
  { path: "password-creation/:txEmailToken", loadChildren: () => import("./password-creation/password-creation.module").then((m) => m.PasswordCreationModule) },
  { path: "user-password-creation/:txEmailToken", loadChildren: () => import("./sub-user-password-creation/sub-user-password-creation.module").then((m) => m.SubUserPasswordCreationModule) },
  { path: "**", redirectTo: "login", pathMatch: "full" },
  // { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
