export enum Permission {
  // Side Menu
  AccessDashboard = "AccessDashboard",
  AccessContracts = "AccessContracts",
  AccessEmployees = "AccessEmployees",
  AccessCalendar = "AccessCalendar",
  AccessInterviews = "AccessInterviews",
  AccessCompanyProfile = "AccessCompanyProfile",
  AccessDealEngine = "AccessDealEngine",
  AccessRoleManagement = "AccessRoleManagement",
  AccessProjects = "AccessProjects",
  AccessRiskManagement = "AccessRiskManagement",
  AccessEscalationManagement = "AccessEscalationManagement",
  AccessProjectFeedback = "AccessProjectFeedback",
  AccessValueAndOpportunity = "AccessValueAndOpportunity",
  AccessTaskManagement = "AccessTaskManagement",
  AccessTimesheet = "AccessTimesheet",
  AccessWorkLogsApproval = "AccessWorkLogsApproval",
  AccessHelpdesk = "AccessHelpdesk",
  AccessFileStorage = "AccessFileStorage",
  AccessProjectReports = "AccessProjectReports",
  AccessProjectGrowth = "AccessProjectGrowth",
  AccessTMarket = "AccessTMarket",
  AccessProjectBilling = "AccessProjectBilling",


  // Dashboard
  ViewDashboard = "ViewDashboard",
  ViewProfileAction = "ViewProfileAction",
  ViewContractAction = "ViewContractAction",
  ViewEmployeeAction = "ViewEmployeeAction",
  ViewInterviewAction = "ViewInterviewAction",

  // Contract
  ViewContracts = "ViewContracts",
  AccessIndividualContract = "AccessIndividualContract",
  // UploadContractAction = "UploadContractAction",
  SignContractAction = "SignContractAction",

  // Employee
  ViewEmployees = "ViewEmployees",
  AddEmployeeAction = "AddEmployeeAction",
  EditEmployeeAction = "EditEmployeeAction",
  ResendEmployeeAction = "ResendEmployeeAction",
  DeleteEmployeeAction = "DeleteEmployeeAction",
  EditEmployeePriceAction = "EditEmployeePriceAction",

  // Calendar
  ViewCalendar = "ViewCalendar",
  ViewInterviewDetailsAction = "ViewInterviewDetailsAction",
  ViewContractDetailsAction = "ViewContractDetailsAction",

  // Interview
  ViewInterviews = "ViewInterviews",
  AcceptAction = "AcceptAction",
  ProposeNewInterviewTimeAction = "ProposeNewInterviewTimeAction",
  CancelInterviewAction = "CancelInterviewAction",
  AccessVideoConference = "AccessVideoConference",

  // Company Profile
  ViewCompanyProfile = "ViewCompanyProfile",
  AccessEditCompanyProfile = "AccessEditCompanyProfile",
  PublishCompanyProfileAction = "PublishCompanyProfileAction",
  EditPrimaryDetailsAction = "EditPrimaryDetailsAction",
  EditExecutiveDetailsAction = "EditExecutiveDetailsAction",
  EditAdditionalDetailsAction = "EditAdditionalDetailsAction",
  EditCompanyDetailsAction = "EditCompanyDetailsAction",
  EditCompanyDocumentsAction = "EditCompanyDocumentsAction",
  EditCertificationsAction = "EditCertificationsAction",

  // Deal Engine
  ViewDealEngine = "ViewDealEngine",
  ParticipateInDealAction = "ParticipateInDealAction",
  ViewDealDetailsAction = "ViewDealDetailsAction",
  ViewCommentInDealAction = "ViewCommentInDealAction",

  // Role Management
  ViewRoleManagement = "ViewRoleManagement",
  AddSubUserAction = "AddSubUserAction",
  EditSubUserAction = "EditSubUserAction",
  DeleteSubUserAction = "DeleteSubUserAction",
  ResendSubUserInviteLinkAction = "ResendSubUserInviteLinkAction",
  ActivateSubUserAction = "ActivateSubUserAction",
  DeactivateSubUserAction = "DeactivateSubUserAction",

  // Project Management
  // Projects
  CreateProjectAction = "CreateProjectAction",
  ViewProjects = "ViewProjects",
  ViewProjectsInTrashAction = "ViewProjectsInTrashAction",
  DeleteProjectAction = "DeleteProjectAction",
  RestoreProjectAction = "RestoreProjectAction",
  ViewProjectDetails = "ViewProjectDetails",
  UpdateStackholderDetails = "UpdateStackholderDetails",
  EditProjectDetailsAction = "EditProjectDetailsAction",
  AddMilestoneAction = "AddMilestoneAction",
  ChangeMilestoneOrderAction = "ChangeMilestoneOrderAction",
  RemoveMilestoneOrderAction = "RemoveMilestoneOrderAction",
  EditAssigneeListAction = "EditAssigneeListAction",
  ViewMilestoneDetails = "ViewMilestoneDetails",
  EditMilestoneDetailsAction = "EditMilestoneDetailsAction",
  AddTaskAction = "AddTaskAction",
  DeleteTaskAction = "DeleteTaskAction",
  ViewTaskDetails = "ViewTaskDetails",


  // Risk Management
  AddRiskAction = "AddRiskAction",
  ViewRiskAction = "ViewRiskAction",
  DeleteRiskAction = "DeleteRiskAction",
  EditRiskAction = "EditRiskAction",

  // Escalation Management
  ViewEscalationAction = "ViewEscalationAction",
  EditEscalationAction = "EditEscalationAction",
  AddEscalationAction = "AddEscalationAction",
  DeleteEscalationAction = "DeleteEscalationAction",

  // Project Feedback
  ViewFeedbacks = "ViewFeedbacks",
  AddFeedback = "AddFeedback",
  EditFeedback = "EditFeedback",
  DeleteFeedback = "DeleteFeedback",

  // Value Addition
  AddValueOrOpportunityAction = "AddValueOrOpportunityAction",
  ViewValueOrOpportunityAction = "ViewValueOrOpportunityAction",
  EditValueOrOpportunityAction = "EditValueOrOpportunityAction",
  DeleteValueOrOpportunityAction = "DeleteValueOrOpportunityAction",

  // Task Management
  ViewTasksProjectWise = "ViewTasksProjectWise",
  EditTaskDetailsAction = "EditTaskDetailsAction",

  // Timesheet
  ViewProjectWiseTimesheet = "ViewProjectWiseTimesheet",
  DownloadTimesheetCSVAction = "DownloadTimesheetCSVAction",

  // Work Logs Approval
  ViewWorklogs = "ViewWorklogs",
  ApproveRejectWorklogAction = "ApproveRejectWorklogAction",

  // Helpdesk
  AddSupportTicketAction = "AddSupportTicketAction",
  ViewSupportTicket = "ViewSupportTicket",
  EditSupportTicketAction = "EditSupportTicketAction",

  // File Storage
  ViewFileStorage = "ViewFileStorage",
  UploadAFileAction = "UploadAFileAction",
  DeleteAFileAction = "DeleteAFileAction",

  // ProjectReports
  ViewProjectReport = "ViewProjectReport",

  //Project Growth
  ViewProjectGrowth = "ViewProjectGrowth",
  SubmitGrowthReportAction = "SubmitGrowthReportAction",


  //TMarket
  ViewProductsIntMarket = "ViewProductsIntMarket",
  AddProductIntMarketAction = "AddProductIntMarketAction",
  ViewtMarketProductDetailsAction = "ViewtMarketProductDetailsAction",
  UpdatetMarketProductDetailsAction = "UpdatetMarketProductDetailsAction",
  DeleteProductFromtMarketAction = "DeleteProductFromtMarketAction",
  ViewtMarketSalesEnquiriesAction = "ViewtMarketSalesEnquiriesAction",
  ViewEnquiryTicketAction = "ViewEnquiryTicketAction",
  UpdateEnquiryTicketAction = "UpdateEnquiryTicketAction",

  // Project Billing
  ViewPmsBilling = "ViewPmsBilling",
  CreateOrUpdatePmsBillingAction = "CreateOrUpdatePmsBillingAction",

}
