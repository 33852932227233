import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EventEmitterService {
  EmpolyeeData = new EventEmitter();
  modelMessageData = new EventEmitter();
  AuthenticateUser = new EventEmitter();
  GetProfileStatusAPISubMethod = new EventEmitter();
  GetProfileStatusSubMethod = new EventEmitter();
  MenuListSubMethod = new EventEmitter();
  PemissionsLoaded = new EventEmitter();

  EmitEmpolyeeData(data: any) {
    this.EmpolyeeData.emit(data);
  }

  modelMessage(data: any) {
    this.modelMessageData.emit(data);
  }

  callAuthenticateUser() {
    this.AuthenticateUser.emit();
  }

  GetProfileStatusAPICallMethod() {
    this.GetProfileStatusAPISubMethod.emit();
  }

  GetProfileStatusCallMethod(data: any) {
    this.GetProfileStatusSubMethod.emit(data);
  }

  MenuListCallMethod() {
    this.MenuListSubMethod.emit();
  }

  EmitPemissionsLoaded() {
    this.PemissionsLoaded.emit();
  }
}
