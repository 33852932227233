import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, APP_INITIALIZER, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OverlayContainer } from "@angular/cdk/overlay";

import { AppRoutingModule } from "./app-routing.module";

// package imports
import { environment } from "./../environments/environment";
import { MaterialModule } from "./material.module";
import { MyInterceptor } from "./config/http.interceptor";
import { ToastrModule } from "ngx-toastr";
import { NgxUiLoaderModule, NgxUiLoaderConfig } from "ngx-ui-loader";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// component imports
import { AppComponent } from "./app.component";
import { DesignSystemComponent } from "./design-system/design-system.component";
import { SiteConstructionComponent } from './site-construction/site-construction.component';

//for guage chart
import { GaugeChartModule } from 'angular-gauge-chart';

import {MatTableModule} from '@angular/material/table';
import { EmojifyModule } from 'angular-emojify';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatTabsModule} from '@angular/material/tabs';

// global declarations
// Sentry.init({
//   dsn: environment.sentry.dsn,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["https://partner.beta.rapidise.io"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 0.75,
// });

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsSize: 30,
  pbColor: "#5EC090",
  fgsColor: "#5EC090",
  overlayColor: "rgba(0, 0, 0, 0.1)",
  hasProgressBar: false,
};

@NgModule({
  declarations: [AppComponent, DesignSystemComponent, SiteConstructionComponent],
  imports: [NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule, MaterialModule, ToastrModule.forRoot(), RecaptchaV3Module, NgbModule,GaugeChartModule,MatTableModule,EmojifyModule,NgxSliderModule,MatTabsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.api_key,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add("solution-dark-theme");
  }
}
